import axios from 'axios'
import { BASE, ACTIVATEMAIL } from '../Requests'
import { toastSuccess, toastDanger } from '../components/alerts/Notifications'; 

export const ResendMail = (props) => {
    const key = localStorage.getItem("e_");
    axios({
        method: "POST",
        url: BASE+ACTIVATEMAIL,
        headers: { Authorization: `Bearer ${key}` },
        credentials: "same-origin",
    }).then( res => {
        if(res.status === 200){
            toastSuccess(res.data.message)
        }
    }).catch( err => {
        if(err.status !== 200){
            toastDanger(("Something went wrong. please try again later"));
        }
    })
}
 