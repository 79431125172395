import React from 'react';
import { Routes, Route , Navigate} from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import LandingMain from './components/LP_main';
import UserLanding from './components/LP_user'
import Registration from './components/Registration'
import Login from './components/Login'
import LogOut from './components/LogOut'
import Layout from './components/Layout';
import ErrorPage from './components/ErrorPage'

export default function App() {
    return(
      <Routes>
      <Route path='/' element={<Layout/>}>
      <Route path='/' element={<LandingMain/>}/>

        <Route element={<RequireAuth allowed={true}/>}> 
          <Route path='/registration' element={<Registration/>}/>
          <Route path='/login' element={<Login/>}/>
        </Route>   
        <Route element={<RequireAuth allowed={false}/>}> 
          <Route path={`/user/dashboard`} element={<UserLanding/>} />
          <Route path='/logout' element={<LogOut/>}/>
          <Route path={'/'} element={<Navigate to="/user/dashboard" replace/>}/>
          <Route path='/registration' element={<Navigate to="/user/dashboard" replace/>}/>
          <Route path='/login' element={<Navigate to="/user/dashboard" replace/>}/>
        </Route>   

        <Route path="*" element={<ErrorPage/>}/>
      </Route>
    </Routes>
  );
}

