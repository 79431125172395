import React, {useState} from 'react';
import {FiTerminal, FiClock, FiAlertTriangle, FiCircle, FiCheckCircle, FiAlertCircle } from 'react-icons/fi'
import AllTransactions from '../Modals/AllTransactions';
import Modal from 'react-bootstrap/Modal'
import EmptyCard from './EmptyCard';
import { useTranslation } from 'react-i18next';

export default function PaymentHistory(props) {
    const [ show, setShow ] = useState();
    const [ state ] = useState();
    const [ isLoading ] = useState();
    const { t } = useTranslation();
    const handleClose = () => {
        setShow(!show); 
    };
 
    return <>
        <div className="col stretch-card mb-3">
            <div className="card border-0 b-radius b-shadow p-4 ">
                <div className="col d-flex align-items-center text-uppercase">
                    <FiTerminal className='mr-3'/>
                    <span>{t('_transactions_')}</span>
                </div>
                    <hr className="mt-3 mb-4"/>
                    {props?.data.length > 0  ? 
                     props?.data.slice(0, 5).map((data, i) => {
                        return (
                            <div key={i} className='col-sm-12 p-0 border-bottom'>
                                <div className="row m-0 pt-2 pb-2 p-0">
                                    <div className="col-6 d-flex">
                                        {data.processed === '0' ?  <span className='d-flex align-items-center mr-3 text-muted fs-5'><FiCircle/></span> : null}
                                        {data.processed === '1' ?  <span className='d-flex align-items-center mr-3 green-text fs-5'><FiCheckCircle/></span> : null}
                                        {data.processed === '2' ?  <span className='d-flex align-items-center mr-3 text-danger fs-5'><FiAlertTriangle/></span> : null}
                                        {data.processed === '3' ?  <span className='d-flex align-items-center mr-3 text-info fs-5'><FiClock/></span> : null}
                                        {data.processed === '4' ?  <span className='d-flex align-items-center mr-3 orange-text fs-5'><FiAlertCircle/></span> : null}
                                        <div className="d-grid">
                                            <span className="fw-bold small">No {data.id}</span> 
                                            <span className="small">{data.created}</span> 
                                        </div>
                                    </div>
                                    <div className="col-6 text-end">
                                        <div className="d-grid">
                                            {data.processed === '0' ?  <div className="fw-bold text-muted">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                            {data.processed === '1' ?  <div className="fw-bold green-text">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                            {data.processed === '2' ?  <div className="fw-bold text-danger">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                            {data.processed === '3' ?  <div className="fw-bold text-info">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                            {data.processed === '4' ?  <div className="fw-bold orange-text">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                            <div className="small m-0">{data.comment ? data.comment : (" ")}</div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    )
                    :
                    <div className='d-flex align-items-center justify-content-center' style={{height:"calc(100% - 20%)"}}>
                        <EmptyCard desc={t('_empty_transaction_')}/>
                    </div>}
                {props?.data.length > 4 ?
                <div className='d-flex justify-content-center mt-3 mb-3'>
                    <button className='net-btn orange-bg orange-shadow' onClick={() => setShow(true)}>{t('_all_transactions_')}</button>
                </div> : null}
            </div>
        </div>


        <Modal show={show} onHide={handleClose}  size="md" centered={true} backdrop='static' keyboard={false}>
            <AllTransactions setShow={setShow} data={state} loading={isLoading}/>
        </Modal>
    </>;
}
