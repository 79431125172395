import React from 'react';
import lg_store from '../../assets/images/lg-store.png'
import app_store from '../../assets/images/app-store.png'
import play_store from '../../assets/images/playstore.png'
import sm_store from '../../assets/images/sm-store.png'
import {alt} from '../../Static'
import { useTranslation } from 'react-i18next';

export default function Landing_appstores() {
    const { t } = useTranslation();
  return <>
    <div className="col-sm-12 col-xs-12 p-0">
        <div className="container pt-4 ">
            <div className="card b-radius b-shadow border-0 mt-5 mb-4 p-5">
                <div className="row">
                    <div className="col-sm-12 col-xs-12 text-muted mb-3">
                        <div className="col-md-6 col-sm-9 float-none mx-auto">
                            <h5 className="w-100 text-center pt-3 pb-5"><span className="gradient-green-bg gradient-text fw-bolder">ARPINET TV</span> {t('app_avaible')}</h5>
                            <div className="row">
                                <div className="col-sm-6 text-center">   
                                    <a href="https://samsung.com" target="blank">
                                        <img className="mt-2 mb-1 col-sm-12 col-xs-8 p-1" src={lg_store} alt={alt}/>
                                    </a>
                                    <a href="https://ru.lgappstv.com/main/tvapp/detail?appId=455499" target="blank">
                                        <img className="mt-1 mb-2 col-sm-12 col-xs-8 p-1" src={sm_store} alt={alt}/>
                                    </a>
                                </div>
                                <div className="col-sm-6 text-center p-1">
                                    <a href="https://apps.apple.com/us/app/arpinet-tv/id1289560227" target="blank">
                                        <img className="mt-2 mb-2 col-sm-5 col-xs-4 p-2" src={app_store} alt={alt}/>
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=am.arpinet.iptvv2" target="blank">
                                        <img className="mt-2 mb-2 col-sm-5 col-xs-4 p-2" src={play_store} alt={alt}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>;
}
