import axios from 'axios'
import { BASE, ABONEMENTINFOUPDATE } from '../Requests'
import { toastSuccess, toastDanger } from '../components/alerts/Notifications'; 


export const AbonementInfoPach = (props) => {
    const key = localStorage.getItem("e_");
    axios({
        method: "PATCH",
        url: BASE+ABONEMENTINFOUPDATE+`${props.id}`+'/',
        data:{
            full_name: props.description,
            parent_code: props.pin,
            password: props.password,
            prolong: props.prolong
        },
        headers: { Authorization: `Bearer ${key}` },
        credentials: "same-origin",
    }).then( res => {
        if(res.status === 200){
            toastSuccess('Changes are saved successfully')
        }
    }).catch( err => {
        if(err.status !== 200){
            toastDanger('Something went wrong! please ty again later')
        }
    })
}
 