import React, { useState, useEffect } from 'react'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { FaSave  } from 'react-icons/fa'
import { FiUser, FiMail, FiEdit, FiSettings  } from 'react-icons/fi'
import { UserInfoPach } from '../../hooks/UserInfoPach'
import { ResendMail } from '../../hooks/ResendMail'
import { ChangeMail } from '../../hooks/ChangeMail'
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'
import { useTranslation } from 'react-i18next';

export default function UserInfo(props) {
    const { t } = useTranslation();

    const key = localStorage.getItem("e_");
    const [ editName, setEditName ] = useState(false)
    const [ editMail, setEditMail ] = useState(false)

    const [ nName, setName ] = useState()
    const [ nNameStatus, setNameStatus ] = useState()
    const [ hideBTN, setHideBTN ] = useState(false)
    const [ mainMail, setMainMail ] = useState(false)
    const [ nMail, setMail ] = useState()
    const [ nMailStatus, setMailStatus ] = useState()

    useEffect(() => {
        if (props.data !== undefined){
            setName(props.data.name);
            setNameStatus(props.data.name_status);
            setMainMail(props.data.main_email)
            setMail(props.data.email)
            setMailStatus(props.data.email_status)
        }
      }, [props.data])

    const handleSendName = (e) => {
        if(nName !== props.data.name){
            UserInfoPach({name: nName})
        } 
    }

    const handleResendMail = (e) => {
        e.preventDefault()
        ResendMail(e)
    }
       
    const handleChangeMail = (e) => {
        if(nMail !== props.data.email){
            ChangeMail({email: nMail})
            setMailStatus(false)
            setHideBTN(true)
        }
    }

  return (

    <div className='col stretch-card mb-3'>        
        <div className="card border-0 b-radius b-shadow p-4">
            <div className="col d-flex align-items-center text-uppercase">
                <FiSettings className='mr-3'/>
                <span>{t('_user_settings_title_')}</span>
            </div>
            
            <hr className="mt-3"/>

            <div className="d-flex justify-content-between mb-3 pl-2 pr-4">
                <div className='d-flex align-items-center pb-0 pt-0'>
                    <FiUser className='h5 mr-3 mt-1'/> 
                    {nNameStatus ? <>
                        {nName === null && editName === false && <div className='text-danger fst-italic'>{t('_user_settings_no_name_')}  </div>}
                        {editName === true ? 
                            <input type={'text'} className="col-auto green-text border-0 border-bottom"
                            value={nName} 
                            onChange={e => setName(e.target.value)} 
                            placeholder={t('_user_settings_enter_name_')}
                            />
                            : 
                            <div className="m-0">{nName}</div> 
                        }
                    </>
                    :
                    <>
                    {editName === true ? 
                        <input type={'text'} className="col-auto green-text border-0 border-bottom"
                        value={nName} 
                        onChange={e => setName(e.target.value)} 
                        placeholder={t('_user_settings_enter_name_')}
                        />
                        : 
                        <div className="m-0">{nName}</div> 
                    }
                    </>
                    }
                </div>
                <button  
                    onClick={!editName ? (e) => {e.preventDefault(); setEditName(!editName)} : (e) => {handleSendName(e); setEditName(!editName)}} 
                    className='btn text-start text-muted mt-0 p-0'>
                    {!editName ? 
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('_user_settings_edit_name')}</Tooltip>}>
                        <div><FiEdit/></div>
                    </OverlayTrigger> 
                    :
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('_user_settings_save')}</Tooltip>}>
                        <div><FaSave/></div>
                    </OverlayTrigger>}
                </button>
            </div>

            <div className="d-flex justify-content-between mb-3 pl-2 pr-4">
                {mainMail?.length > 1 ? 
 <>
                {nMailStatus ? 
                    <div className='d-flex '>
                        <FiMail className='h5 mr-3'/> 
                        {editMail === true ? 
                            <MaskedInput type="text" className={"col-auto green-text border-0 border-bottom"} required
                            mask={emailMask}  
                            value={nMail}
                            onChange={e => setMail(e.target.value)}
                            placeholder="someone@example.com"
                            />
                            : <>
                            <div className="m-0 col">{nMail}</div> 
                            </>
                        }
                    </div>   
                    : 
                    <div className='d-flex '>
                        <FiMail className='h5 mr-3'/> 
                        {editMail === true ? 
                            <MaskedInput type="text" className={"col-auto green-text border-0 border-bottom"} required
                            mask={emailMask}  
                            value={nMail}
                            onChange={e => setMail(e.target.value)}
                            placeholder="someone@example.com"
                            />
                            : <>
                            {nMail === null && editMail === false ? mainMail :
                            <div>
                                <div className="m-0 col">{mainMail}</div> 
                                <div className='text-danger small col'>{nMail} {t('_user_settings_mail_not_confirmed')}</div>
                                <button onClick={(e) => handleResendMail(e)} className='btn border-0 text-danger p-0'>{t('_user_settings_resent_mail')}</button>
                            </div>
                            } </>
                        }
                    </div>  
                }
 </>               
                : 
                    <div className='d-flex '>
                        <FiMail className='h5 mr-3'/> 
                        {mainMail?.length > 0 && <div className='text-danger fst-italic'>{t('_user_settings_has_no_mail')} </div>}
                        {editMail === true ? 
                            <MaskedInput type="text" className={"col-auto green-text border-0 border-bottom"} required
                            mask={emailMask}  
                            value={nMail}
                            onChange={e => setMail(e.target.value)}
                            placeholder="someone@example.com"
                            />
                            : <>
                            {mainMail?.length > 0  && nMail !== null && nMailStatus === false  ? 

                            <div>
                                <div className="m-0 col">{mainMail.length < 1 ? nMail : mainMail}</div> 
                                <div className='text-danger small col'>{nMail} {t('_user_settings_mail_not_confirmed')}</div>
                                <button onClick={(e) => handleResendMail(e)} className='btn border-0 text-danger p-0'> {t('_user_settings_resent_mail')}</button>
                            </div>
                             :
                             <div className='text-danger fst-italic'>{nMail} {t('_user_settings_mail_not_confirmed')}</div>
                            } 
                            </>
                        }
                    </div>  
                
                }
                {hideBTN ? null
                :
                <button  
                    onClick={!editMail ? (e) => {e.preventDefault(); setEditMail(!editMail);} : (e) => {handleChangeMail(e); setEditMail(!editMail)}} 
                    className='btn text-start text-muted mt-0 p-0'>
                    {!editMail ? 
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('_user_settings_edit_mail')}</Tooltip>}>
                        <div><FiEdit/></div>
                    </OverlayTrigger> 
                    :
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('_user_settings_save')}</Tooltip>}>
                        <div><FaSave/></div>
                    </OverlayTrigger>}
                </button>
                }
            </div>
        </div>
    </div>
  )
}
