import React from 'react';
import {alt} from '../../Static'
import banner from '../../assets/images/slider_bg.jpg'


export default function Landing_banner() {
  return <>
            <div className="col-sm-12 col-xs-12 p-0">
                <div className="container p-0">
                  <img className="w-100" src={banner} alt={alt}/>
                </div>
            </div>
              
            <div className="sep-line"></div>
        </>;
}
