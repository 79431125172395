import React, {useEffect} from 'react';

export default function LogOut() {
  useEffect(() => {
    localStorage.removeItem("key");
    localStorage.removeItem("e_");
  }, [])
  window.location.replace("/")
  
  return <>
  <div className='text-center'>
    <div className='display-3 green-text fw-bold'>See you soon;-)</div>
  </div>
  </>;
}
