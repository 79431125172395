import toast from 'react-hot-toast';

export const toastSuccess = (props) => {
    toast.custom(
        <div className='pt-0 pl-3 pr-3 pb-2'>
            <div className="b-radius green-shadow green-bg text-white p-3" role="alert">
                {props}
            </div>
        </div>
  );
}

export const toastDanger = (props) => {
    toast.custom(
        <div className='pt-0 pl-3 pr-3 pb-2'>
            <div className="b-radius b-shadow bg-danger text-white p-3" role="alert">
                {props}
            </div>
        </div>
  );
}

export const toastWarning= (props) => {
    toast.custom(
        <div className='pt-0 pl-3 pr-3 pb-2'>
            <div className="b-radius orange-shadow orange-bg text-white p-3" role="alert">
                {props}
            </div>
        </div>
  );
}