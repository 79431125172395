import React, { useState, useEffect } from 'react';
import AccountInformationFooter from '../UI/AccountInformationFooter';
import { BsCheckCircleFill, BsExclamationTriangleFill } from "react-icons/bs";
import { FiPlus, FiInfo, FiCheck } from "react-icons/fi";
import axios from 'axios';
import { BASE, SUBSCRIPTIONS, ABONEMENTINFO } from '../../Requests';
import Modal from 'react-bootstrap/Modal'
import bonus from '../../assets/images/dashboard/bonus.png'
import UserInfoModalContent from '../Modals/UserInfoModalContent';
import PaymentWindow from '../Modals/PaymentWindow'
import RemoveModal from '../Modals/RemoveModal';
import { useTranslation } from 'react-i18next';

// clear local storage
// i_ for user id
// e_ for access token
localStorage.removeItem('i_')

export default function AccountInformation(props) {  
    const { t } = useTranslation();
    const key = localStorage.getItem("e_");
    //user id
    const id = localStorage.getItem('i_');
    const [ info, setInfo ] = useState(null)
    const [ show, setShow ] = useState();
    const [ plan, setPlan ] = useState();
    const [ plan_id, setPlan_id ] = useState(['1'])
    const [isAnnual, setIsAnnual] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [removePlanModal, setRemovePlanModal] = useState(false);
    // payment modal
    const [paymentModal, setPaymentModal] = useState(false)
    //USER INFO
    const [userInfo, setUserInfo] = useState();
    const [userAbonementID, setUserAbonementID] = useState();
    const [userInfoModalShow, setUserInfoModalShow] = useState();

    useEffect(() => {
      if (info === null ) {
          setInfo(props.data)
      }
    }, [info])
    

    const handlePlanShow = (e) => {
        setPlan_id(['1'])
        setPlan(null)
        //user id
        localStorage.setItem("i_", e.currentTarget.id);
        loadPlans()
        setIsAnnual(false)
        setTimeout(() => {
            setShow(!show)
        }, 200);
    };
    const handleClose = () => {
        setShow(!show); 
        setUserInfoModalShow(false)
        setPaymentModal(false)
        setIsChecked()
        setTimeout(() => {
            setPlan(null)
            setPlan_id()
            localStorage.removeItem('i_')
            localStorage.removeItem('p_')
        }, 200);
    };

    const loadPlans = (e) => {
        if(localStorage.getItem('i_') != undefined){
            axios({
                method: 'POST',
                url: BASE+SUBSCRIPTIONS,
                headers: { Authorization: `Bearer ${key}` },
                credentials: "same-origin",
                data: {"tariff": plan_id, "abonement": localStorage.getItem('i_')}
            }).then(res => {
                setPlan(res.data)
            }).catch(err => {console.log(err.message);}).finally()
        }
    }

    const handleChangePlan = (e) => {
        switch(e.target.checked) {
            case true: 
                setPlan_id([...plan_id,e.currentTarget.dataset.id]); 
                setIsChecked(true)
                break
            case false: 
            setIsChecked(false)
                setPlan_id([...plan_id.filter(function(id_s) { 
                    return id_s !== e.currentTarget.dataset.id })
                ]); 
                break
            default: break
        }
    }

    useEffect(() => {
        loadPlans()
    }, [plan_id])

    function checkboxChange(e){
        if(e.target.checked === false){
            localStorage.setItem("r_", e.target.dataset.id)
            setRemovePlanModal(true)
            setShow(false)
            loadPlans()
        }
    }

    function annualSwitcher(e){
        if(e.target.id === 'monthlySwitcher'){
            setIsAnnual(false)
        } else if (e.target.id === 'yearlySwitcher') {
            setIsAnnual(true)
        }
    }

    const handleUserInfo = (e) => {
        setUserInfo()
        setUserAbonementID(e.currentTarget.dataset.id)
        axios({
            method: "GET",
            url: BASE+ABONEMENTINFO+e.currentTarget.dataset.id,
            headers: { Authorization: `Bearer ${key}` },
            credentials: "same-origin",
        }).then(res => {
            setUserInfo(res.data)
        })
        setUserInfoModalShow(true)
    }
    
    const makePayment = (e) => {
        setPaymentModal(true)
        setShow(false)
        localStorage.setItem('p_', document.querySelector('#price').innerHTML)
    }
    const handleHideModal = (e) => {
        setShow(true)
        setPaymentModal(false)
    }


    const paymentData = {range: isAnnual, plan: plan_id }
    return <>

    <div className="col match-height mb-3">
        <div className="card border-0 b-radius b-shadow p-4">
        <div className="col d-flex align-items-center text-uppercase">
                <FiInfo className='mr-3'/>
                <span>{t('_account_information_title')}</span>
            </div>


            <div className="col-sm-12 p-0">
                <hr className="mt-3 mb-4"/>

                <div className='table-responsive'>
                    <table className="table datatable text-start table-borderless">
                        <thead>
                            <tr className='border-bottom text-center'>
                                <th style={{width: "20px", minWidth: "20px", padding: '0'}}>{""}</th>
                                <th colSpan={1}>{t('_account_')}</th>
                                <th colSpan={1}>{t('_account_password')}</th>
                                <th colSpan={3}>{t('_account_plan')}</th>
                                <th colSpan={1}>{t('_account_status')}</th>
                                <th colSpan={1}>{t('_account_autopay')}</th>
                                <th colSpan={2}>{t('_account_avaible')}</th>
                                <th colSpan={2}>{t('_account_actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {info?.map((data, i) => {
                                return (
                                    <tr key={i} className="text-center pt-2 pb-2">
                                        <td style={{width: "20px", minWidth: "20px", padding: '0'}}><span className={data.deactivation_date >= 10 ? "status-badge bg-success" : "status-badge bg-danger animated infinite flash slow"}></span></td>
                                        <td colSpan={1} >
                                            {data.login}</td>
                                        <td colSpan={1} >
                                            {data.password}</td>
                                        <td colSpan={3}>
                                        {data?.tariff.map((plan, i) => {
                                            return (<li key={i} className="plan_name">{plan.name}</li>)
                                        })}
                                        </td>

                                        <td colSpan={1}>{data.active ? <BsCheckCircleFill  className="text-success"/> : <BsExclamationTriangleFill  className="text-danger"/>}</td> 
                                        <td colSpan={1}>{data.prolong ? "Yes" : "No"}</td>
                                        <td colSpan={2} className={data.deactivation_date >= 10 ? 'green-text text-center' : 'text-danger text-center animated infinite flash slow'}>
                                            {data.deactivation_date} {t('_account_days_left')}
                                        </td>

                                        <td colSpan={2}>
                                            <div className='d-flex'>
                                                <button data-id={data.id} className='net-btn orange-bg pl-3 pr-3 pt-1 pb-1 mr-2 ' onClick={(e) => handleUserInfo(e)} > 
                                                 {t('_account_edit')}
                                                </button>
                                                <button id={data.id} className='net-btn green-bg pl-3 pr-3 pt-1 pb-1' onClick={(e) => handlePlanShow(e)}>
                                                     {t('_account_peyment')}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <AccountInformationFooter/>
            </div>
        </div>
    </div>


    {/* PaymentModal settings and info starts here */}
    <Modal show={removePlanModal} onHide={handleHideModal}  size="md" centered={true} backdrop='static' keyboard={false}>
        <RemoveModal setRemovePlanModal={setRemovePlanModal} loadPlans={loadPlans} setShow={setShow}/>
    </Modal>
    {/* PaymentModal settings and info ends here */}

    <Modal show={show} onHide={handleClose} size="md" centered={true} backdrop='static' keyboard={false}>
        <div className='col-sm-12 b-radius overflow-hiden b-shadow bg-white p-0'>
            <Modal.Body>

            {plan?.invoice ? <>
                <form id="my-awesome-dropzone" className="row p-0 m-0 ">
                    <div className="col-sm-10 mx-auto p-4">
                        <div className="card b-radius b-shadow pb-1">
                            {plan?.tariffs.map((data, i) => {
                                return (
                                <>
                                <div key={data.id} data-id={data.id} className={"plan-check price-box mb-0 pl-2 pr-2 pt-3 pb-3 b-radius "+ `${data.is_basic && data.is_active ? " disabled" : ""}` }>
                                    <a role={'button'} className={'quicksand mb-0'}>
                                        <input type="checkbox" 
                                            data-id={data.id}
                                            id={"monthly"} 
                                            defaultChecked={data.is_basic || data.is_active || isChecked} 
                                            onChange={data.is_active === true ? (e) => checkboxChange(e) : (e) => handleChangePlan(e)}/>
                                        <label htmlFor={"monthly"} className='price-body row m-0 p-0'>
                                            <div className='h5 mb-0'>{data.name}</div>
                                            {data.is_basic ? <div className='small'>{t('_default_plan_remove')}</div>  : null}
                                            <div className='mt-4'>
                                                <span className='h2 mb-0 green-text line-height-0'>{data.price}</span>
                                                <span className='small'>{t('_amd_')}</span>
                                            </div>
                                            <div className='remove-plan b-radius'>
                                                <span className='mr-2'>{t('_remove_plan')}</span>
                                            </div>
                                            <div className='add-a-plan'>
                                                <span className='mr-2'>{t('_add_plan')}</span>
                                                <FiPlus className="text-white orange-bg orange-shadow"/>
                                            </div>
                                            <div className='selected-plan active-plan'>
                                                <FiCheck className="text-white green-bg green-shadow"/>
                                            </div> 
                                        </label>
                                    </a>
                                </div>
                                {props.date ? 
                                <div className='mt-4 pb-4 text-center'>
                                    <span className='small'>{t('_plan_extra_for')}</span>
                                    <span className='h1 mb-0 orange-text line-height-0'> {data.expairy} </span>
                                    <span className='small'>{t('_plan_extra_days')}</span>
                                    {props.bonusDays ? 
                                        <span> 
                                            <br/>
                                            <span className='h1 mb-0 green-text line--0'>+ {props.bonusDays} </span>  
                                            <span className='small'>{t('_plan_extra_months')}</span>
                                        </span>
                                        : null}
                                </div>
                                : null}
                            </>
                            )
                            })}
                        </div>
                    </div>
                    
                    <div className='col-sm-10 mx-auto'>
                        <div className="row">
                            <div className='col-xs-8 col-sm-10 mx-auto btn-group b-radius b-shadow p-0' role="group">
                                <input type="radio" className="btn-check" name="options" id="monthlySwitcher" defaultChecked={!isAnnual} onClick={(e) => {setIsAnnual(true); annualSwitcher(e)}}/>
                                <label className="btn monthly b-radius" htmlFor="monthlySwitcher">{t('_plan_extra_monthly')} </label>

                                <input type="radio" className="btn-check" name="options" id="yearlySwitcher" defaultChecked={isAnnual} onClick={(e) => annualSwitcher(e)}/>
                                <label className="btn yearly b-radius" htmlFor="yearlySwitcher">{t('_plan_extra_yearly')}
                                + <img className='small-bonus animated infinite bounce delay-1s slower' src={bonus} alt={'arpinet tv'}/>
                                </label>
                            </div>
                            <div className='col-12 p-0 d-flex justify-content-center align-items-center position-relative'>
                                <div className={'text-center quicksand mt-4 mb-4'}>
                                    <div id="expires" className='mt-0 fs-5'>{t('_plan_extra_expires')}: {isAnnual ? `${plan?.invoice.yearly.expiry}` : `${plan?.invoice.mountly.expiry}`}</div> 
                                        {isAnnual ? ( <span className='green-text'>+ {t('_plan_extra_bonus_mounths')}</span> ): null}
                                    <div className='mt-4 mb-3'>
                                        <span id="price" className='fs-1 fw-bold mb-0 green-text line-height-0'>{isAnnual ? `${plan?.invoice.yearly.price}` : `${plan?.invoice.mountly.price}`}</span>
                                        <span className='small'>{t('_amd_')}</span>
                                    </div>
                                </div>
                                {isAnnual && <img className='big-bonus animated infinite pulse delay-5s slower' src={bonus} alt={'arpinet tv'}/>}
                            </div>
                        </div>
                    </div>
                </form>
                <div className='text-center justify-content-center border-top pt-4 pb-3'>
                    <button className='net-btn orange-bg orange-shadow pl-4 pr-4 mr-3' onClick={handleClose}>{t('_cancel_')}</button>
                    <button className='net-btn green-bg green-shadow pl-4 pr-4' onClick={(e) => makePayment(e)}>{t('_pay_')} {isAnnual ? `${plan?.invoice.yearly.price}` : `${plan?.invoice.mountly.price}`} {t('_amd_')}</button>
                </div>
                </>  : null}
            </Modal.Body>
        </div>
    </Modal>

    {/* PaymentModal settings and info starts here */}
    <Modal show={paymentModal} onHide={handleHideModal}  size="md" centered={true} backdrop='static' keyboard={false}>
        <PaymentWindow data={paymentData} setPaymentModal={setPaymentModal} setShow={setShow} fetch={props.fetch}/>
    </Modal>
    {/* PaymentModal settings and info ends here */}

    {/* UserModal settings and info starts here */}
    <Modal show={userInfoModalShow} onHide={handleClose} size="md" centered={true} backdrop='static' keyboard={false}>
            <UserInfoModalContent data={userInfo} abonement={userAbonementID} setUserInfoModalShow={setUserInfoModalShow}/>
    </Modal>
    {/* UserModal settings and info ends here */}
    
    </>;
}
