import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios'
import {BASE, AUTH, CONFIRM} from '../Requests'
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input/mobile'
import InputMask from 'react-input-mask';
import small_logo from '../assets/logo-small.jpg'
import {alt} from '../Static'
import {FiArrowRight, FiLoader}  from 'react-icons/fi'
import { useTranslation } from 'react-i18next';

export default function Login(props){
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const from = location.state?.from?.pathname || "/";

    const [user_mobile_number, setUser_mobile_number] = useState(true);
    const [user_mobile_confiramtion, setUser_mobile_confiramtion] = useState(false);
    const [userRegister, setUserRegister] = useState(false)
    const [auth_number, setAuth_number] = useState('');
    const [auth_code, setAuth_code] = useState('*');
    const [error_message, setError_message] = useState('');
    const [error_code, setError_code] = useState('');
    const [error_timer, setError_timer] = useState(null);
    const [blocked, setBlocked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [submitCodeerror, setSubmitCodeerror] = useState(false)

    

    const [dealerForm, setDealerForm] = useState(false)
    

    const inputRef = useRef(null);
    const intervalRef = useRef();

    const SubmitNumber = async (e) => {
        e.preventDefault();
        if(isValidPhoneNumber(auth_number)){
            axios({
                method: 'POST',
                url: BASE+AUTH,
                data: {"mobile": auth_number}
            }).then((res)=>{
                setUser_mobile_number(false)
                setUser_mobile_confiramtion(true)
            }
            ).catch((error) => {
                console.log(error.code);
                if (error.response.status===429){
                    setError_code(error.response.data.error_code)
                    setError_timer(error.response.data.timer)
                };
                if (error.response.status === 400){
                    if(error.response.data.error_code == 3){
                        setUser_mobile_number(false)
                        setUser_mobile_confiramtion(false)
                        setUserRegister(true)
                    };
                    if(error.response.data.error_code == 1){
                        setBlocked(error.response.data.error_code)
                        setError_timer(error.response.data.error_message)
                    };
                    if(error.response.data.error_code == 4){
                        setDealerForm(true)
                        setUser_mobile_number(false)
        
                    };
                };
                
            });
        }
    };
    

    useEffect(() => {
        if(auth_code.length === 4){
            setLoading(true)
            SubmitCode()
        } else {
            setLoading(false)
        }
    })


    function SubmitCode(e) {
        // e.preventDefault()
        axios({
            method: 'POST',
            url: BASE+CONFIRM,
            data: {"mobile": auth_number, "code": auth_code}
        }).then(res => {
            if(res.status===200){

                localStorage.setItem("r_", JSON.stringify(res?.data.refresh));
                localStorage.setItem("key", JSON.stringify([res?.data.access, res?.data.dealer, res?.data.refresh]));
                if(JSON.parse(localStorage.getItem("key")) !== undefined){
                    window.location.replace("/user/dashboard")
                } 
        }
        }).catch((error) => {
            console.log(error);
            if (error.response.data.error_code == 1) {
                setError_timer(error.response.data.timer);
                setError_message('')
                setSubmitCodeerror(true)
            }
            if (error.response.data.error_code == 2) {
                setError_message(error.response.data.error_message)
            }
        });
    };

    
    function SubmitRegistration(e){
        e.preventDefault()
        localStorage.setItem("Number", auth_number)
        navigate('/registration', from, { replace: true })
    };

    const seconds = error_timer % 60;
    const minutesRemaining = (error_timer - seconds) / 60;
    const minutes = minutesRemaining % 60;
    const hours = (minutesRemaining - minutes) / 60;
    const timeDigits = (num) => String(num).padStart(2, '0');

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setError_timer((t) => t - 1);
        }, 1000);
        return () => clearInterval(intervalRef.current);
    }, [error_timer]);

    useEffect(() => {
        if (error_timer <= 0) {
          clearInterval(intervalRef.current);
        }
    }, [error_timer]);

return (
    <>             
    <div className="col-sm-12 col-xs-12 p-0">
        <div className="container pt-5 pb-5">
            <div className="row align-items-center">
                <div className="col-lg-5 col-md-7 col-sm-10 mx-auto">
                    <div className="col-sm-12 p-4 card b-radius b-shadow border-0">
                        <div className="text-center mb-2 mt-2">
                            <img style={{width: '70px'}} src={small_logo} alt={alt}/>
                            <div className="line-inside"><span>{t('log_in')}</span></div>
                        </div>
                        {dealerForm && 
                        <div>
                            <div className="col-sm-12 p-0 text-center pt-3 pb-4">
                                <div className="text-danger fst-italic">{t('_login_denided')}</div>
                            </div>
                            <div className="col-sm-12 p-0 text-center mt-4">
                                <button className="net-btn green-bg green-shadow pl-4 pr-4"
                                onClick={(e)=> {setUser_mobile_number(true); setDealerForm(false)}}>{t("_go_back")} <FiArrowRight className="ml-2"/></button>
                            </div>
                        </div>
                        }

                        {blocked ? 
                        <>
                            <div className="col-sm-12 text-center">
                                <div className="col-12 text-danger mt-2 mb-4">{auth_number} {t('_is_blocked_0')}  
                                <br/>  {t('_is_blocked_1')}
                                <br/>  {t('_is_blocked_2')} {hours > 0 && timeDigits(hours) + ':'}{minutes > 0 && timeDigits(minutes) + ':'}{seconds && timeDigits(seconds)}
                                </div>
                            </div>
                        </> : <>

                        {userRegister && <>
                        <form onSubmit={SubmitRegistration}>
                            <div className="text-center mb-5">{t("_go_to_reg_0")}<br/>{t("_go_to_reg_1")}</div>
                            <div className="form-group mb-3 d-inline-flex col-sm-12">
                                <PhoneInput
                                    autoFocus
                                    international
                                    className="form-control b-radius p-2 pl-4 pr-4 mb-3 disabled"
                                    disabled
                                    ref={inputRef}
                                    value={auth_number}
                                    />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-10 mx-auto pt-3 mb-2 text-center">
                                {error_timer ? 
                                    <button className="net-btn green-bg green-shadow pl-4 pr-4" disabled>{t('_too_many_reqs')} {error_timer}</button>
                                    :
                                    <>
                                    {auth_number && isValidPhoneNumber(auth_number) ? <>
                                        <button onSubmit={SubmitRegistration} className="net-btn orange-bg orange-shadow pl-4 pr-4">{t('_create_an_account')} <FiArrowRight className="ml-2"/></button>
                                        </> : <>
                                        <button className="net-btn green-bg green-shadow pl-4 pr-4" disabled>{t('_create_an_account')} <FiArrowRight className="ml-2"/></button>
                                    </>} 
                                </>}
                            </div>
                        </form>
                        
                        </>}
                        
                        {user_mobile_number && <>
                        <form onSubmit={SubmitNumber}>
                            <div className="text-center mb-5">
                                {t('_login_desc_')}
                            </div>
                            <div className="form-group mb-3 col-sm-12">
                                <PhoneInput
                                    required
                                    autoFocus
                                    international
                                    displayInitialValueAsLocalNumber
                                    placeholder={t('_phone_number_placeholder')}
                                    className="form-control b-radius p-2 pl-4 pr-4 mb-3"
                                    ref={inputRef}
                                    value={auth_number}
                                    onChange={setAuth_number}
                                    />
                            </div>
                            <div className="col-sm-12 mx-auto pt-0 mb-2 text-center">
                                {error_timer ? 
                                <>
                                    <div className="text-danger mb-4">{t('_too_many_reqs')} {t('_is_blocked_2')}</div>
                                    <button className="net-btn green-bg green-shadow pl-4 pr-4" disabled>
                                        {t('_wait')} {hours > 0 && timeDigits(hours) + ':'}{minutes > 0 && timeDigits(minutes) + ':'}{seconds && timeDigits(seconds)}
                                    </button>
                                </>:
                                    <>
                                    {auth_number && isValidPhoneNumber(auth_number) ? <>
                                        <button onSubmit={SubmitNumber} className="net-btn green-bg green-shadow pl-4 pr-4">{t('log_in')} <FiArrowRight className="ml-2"/></button>
                                        </> : <>
                                        <button className="net-btn green-bg green-shadow pl-4 pr-4" disabled>{t('log_in')} <FiArrowRight className="ml-2"/></button>
                                    </>} 
                                </>}
                            </div>
                        </form>
                        
                        </>}

                        {user_mobile_confiramtion ? <>                          
                            <div className="text-center mb-5"> {t('_confirm_call_number_0')} {auth_number}, {t('_confirm_call_number_1')} 
                            <span className="text-muted"> 060 XX <span className="text-danger">XXXX</span></span>
                            {submitCodeerror ? <><div className="col-12 text-danger text-center mt-3">{t('_confirm_call_number_error')}</div></> : null}
                            </div>

                            <form onSubmit={SubmitCode}>
                                <div className="form-group mb-3 col-sm-12">
                                <InputMask
                                    required
                                    autoFocus
                                    mask="9999"
                                    disabled={error_timer}
                                    className='form-control b-radius p-2 pl-4 pr-4 text-center fw-bold ls-30 mb-3'
                                    value={auth_code} 
                                    onChange={e => setAuth_code(e.target.value.replace(/\D/,''))}
                                />
                                </div>
                                <div className="col-sm-12 mx-auto pt-0 mb-2 text-center">
                                {error_timer ? 
                                    <>
                                    <div className="text-danger mb-4"> {t('_confirm_call_number_error_desc_')}</div>
                                    <button className="net-btn green-bg green-shadow pl-4 pr-4" disabled>
                                        {t('_wait')} {hours > 0 && timeDigits(hours) + ':'}{minutes > 0 && timeDigits(minutes) + ':'}{seconds && timeDigits(seconds)}
                                    </button></>
                                    :
                                    <>
                                    <div className="col-lg-6 col-md-6 col-sm-10 mx-auto pt-3 mb-2 text-center">
                                        <button onSubmit={SubmitCode} className="net-btn green-bg green-shadow pl-4 pr-4" disabled={auth_code && auth_code.length<= 3 ? true : false}>
                                            {t('_confirm_')} {loading ? <FiLoader className="ml-2 spin"/> : <FiArrowRight className="ml-2"/>} </button>
                                    </div>
                                </>}
                                </div>

                            </form>
                        </>
                        : null}
                        </>}
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
);
}