import React from 'react'
import { BsCheckCircleFill, BsExclamationTriangleFill } from "react-icons/bs";
import { useTranslation } from 'react-i18next';

export default function AccountInformationFooter() {
  const { t } = useTranslation();
  return (
    <div className="col-sm-12 p-0 mt-5 small border-top pt-3">
        <div className="row p-0 m-0">
            <div className="col-md-12 col-xs-12 d-flex align-items-center">
                <BsCheckCircleFill  className="text-success mr-2"/> {t('_account_is_active')}
            </div>
            <div className="col-md-12 col-xs-12 d-flex align-items-center">
                <BsExclamationTriangleFill  className="text-danger mr-2 mr-2"/> {t('_account_low_balance')}
            </div>
        </div>
    </div>
  )
}
