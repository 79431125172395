import React, {useState, useEffect, useRef} from 'react'
import {BASE, REG, REGCONFIRM} from '../Requests'
import axios from 'axios'
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input/mobile'
import InputMask from "react-input-mask";
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'
import small_logo from '../assets/logo-small.jpg'
import { alt } from '../Static'
import { useNavigate, useLocation } from "react-router-dom";
import { FiArrowRight, FiLoader, FiCheck } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

export default function Registration(props) {
    // const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const from = location.state?.from?.pathname || "/";

    const storageNumber = localStorage.getItem('Number')
    const [user_mobile_number, setUser_mobile_number] = useState(true);
    const [user_mobile_confiramtion, setUser_mobile_confiramtion] = useState(false);
    const [UserNumber, setUserNumber] = useState(storageNumber)
    const [UserName, setUserName] = useState('')
    const [UserMail, setUserMail] = useState('')
    const [auth_code, setAuth_code] = useState('*');
    const [error_message, setError_message] = useState('');
    const [error_timer, setError_timer] = useState(null);
    const [progress, setProgress ] = useState(0)
    const [successForm, setSuccessForm] = useState(false)
    const inputRef = useRef(null);
    const intervalRef = useRef();
    const [loading, setLoading] = useState(false)

    function onChangeNumber(e){
        if(storageNumber){
            localStorage.removeItem('Number');
            setUserNumber('')
        }
            setUserNumber(e)
            // console.log(e);
    }
    
    function SubmitNumber (e) {
        // e.preventDefault()
        if(isValidPhoneNumber(UserNumber)){
            axios({
                method: 'POST',
                url: BASE+REG,
                data: {"mobile": UserNumber}
            }).then((res)=>{
                if(res.status===200){
                    setUser_mobile_number(false);
                    setUser_mobile_confiramtion(true)
                    setProgress(50)
                }
            }
            ).catch((error) => {
                if(error.response.data.error_code == 3){
                    setError_message(error.response.data.error_message)
                }
            });
        }
        e.preventDefault()
    };
    
    useEffect(() => {
        if(auth_code.length === 4){
            setLoading(true)
            SubmitCode()
        } else {
            setLoading(false)
        }
    })

    function SubmitCode(e) {
        // e.preventDefault()
        axios({
            method: 'POST',
            url: BASE+REGCONFIRM,
            data: {"mobile": UserNumber, "code": auth_code, "full_name": UserName, "email": UserMail}
        }).then(res => {
            if(res.status===200){
                localStorage.setItem("r_", JSON.stringify(res?.data.refresh));
                localStorage.setItem("key", JSON.stringify([res?.data.access, res?.data.dealer, res?.data.refresh]));
                if(JSON.parse(localStorage.getItem("key")) !== undefined){
                    setTimeout(() => {
                        window.location.replace("/user/dashboard")
                    }, 2000);
                } 
                setProgress(100)
                setSuccessForm(true)
                setUser_mobile_confiramtion(false)
                localStorage.removeItem("Number")
            }
        }).catch((error) => {
            if(error.response.data.error_code == 1){
                console.log(error.response.data);
                setError_timer(error.response.data.timer)
            }
            if(error.response.data.error_code == 2){
                setError_message(error.response.data.error_message)
            }
        });
    };

    const seconds = error_timer % 60;
    const minutesRemaining = (error_timer - seconds) / 60;
    const minutes = minutesRemaining % 60;
    const hours = (minutesRemaining - minutes) / 60;
    const timeDigits = (num) => String(num).padStart(2, '0');

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setError_timer((t) => t - 1);
        }, 1000);
        return () => clearInterval(intervalRef.current);
    }, [error_timer]);

    useEffect(() => {
        if (error_timer <= 0) {
          clearInterval(intervalRef.current);
        }
    }, [error_timer]);

    return (
    <>             
    <div className="col-sm-12 col-xs-12 p-0">
        <div className="container pt-5 pb-5">
            <div className="row align-items-center">
                <div className="col-lg-5 col-md-7 col-sm-10 mx-auto">
                    <div className="col-sm-12 p-4 card b-radius b-shadow border-0">
                        <div className="text-center mb-2 mt-2">
                            <img style={{width: '70px'}} src={small_logo} alt={alt}/>
                            <div className="line-inside"><span>{t('sign_up')}</span></div>
                        </div>

                        <div className="steps d-flex align-items-center position-relative justify-content-between mb-3 ">
                            <div className="progress step-progress">
                                <div className="progress-bar bg-success" role="progressbar" style={{width: progress + '%' }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                            </div>  
                            <div className={'step-item bg-white text-center'} style={{width: "40px", height: '40px'}}>
                                {progress > 0 ? <FiCheck className='green-text'/> : null}
                                {progress === 0 ? <FiLoader className='spin'/> : null}
                            </div>
                            <div className={'step-item bg-white text-center'} style={{width: "40px", height: '40px'}}>
                                {progress < 50 ? <FiArrowRight className='text-muted'/> : null}
                                {progress === 50 ? <FiLoader className='spin'/> : null}
                                {progress > 50 ? <FiCheck className='green-text'/> : null}
                            </div>
                            <div className={'step-item bg-white text-center'} style={{width: "40px", height: '40px'}}>
                                {progress < 100 ? <FiArrowRight className='text-muted'/> : null}
                                {progress === 100 ? <FiLoader className='spin'/> : null}
                                {progress > 100 ? <FiCheck className='green-text'/> : null}
                            </div>
                        </div>

                        {user_mobile_number && <>
                        <form onSubmit={SubmitNumber}>
                            {storageNumber ? 
                                <div className="text-center">
                                    <span className='green-text'>{UserNumber}</span> {t('_reg_avaible_')}
                                </div>
                            : 
                                <div className="text-center">
                                    {t('_login_desc_')}
                                </div>
                            }
                            {error_message ? <div className='col-12 text-danger text-center mb-3 mt-3'>{error_message}</div> : null}

                            <div className="form-floating mb-3 mt-3">
                                <input type="text" className="form-control b-radius mt-2" id="full_name" placeholder={t('_full_name')}
                                autoFocus
                                value={UserName}
                                onChange={e => setUserName(e.target.value)}
                                />
                                <label htmlFor="full_name">{t('_full_name')}</label>
                            </div>
                            <div className="form-floating mb-3">
                            <MaskedInput type="text" className={"form-control b-radius mt-2"} id="e_mail" required
                                mask={emailMask}  
                                value={UserMail}
                                onChange={e => setUserMail(e.target.value)}
                                placeholder="someone@example.com"
                            />
                                <label htmlFor="e_mail">{t('_e_mail')}</label>
                            </div>
                            <div className="form-group mb-3 d-inline-flex col-sm-12">
                                <PhoneInput
                                    required
                                    international
                                    placeholder={t('_phone_number_placeholder')}
                                    className="form-control b-radius p-2 pl-4 pr-4 mb-3"
                                    ref={inputRef}
                                    value={UserNumber}
                                    onChange={onChangeNumber}
                                    />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-10 mx-auto pt-3 mb-2 text-center">                              
                                {UserNumber && isValidPhoneNumber(UserNumber) ? <>
                                    <button onSubmit={SubmitNumber} className="net-btn green-bg green-shadow pl-4 pr-4">{t('sign_up')} <i className="ft-arrow-right ml-2"></i></button>
                                    </> : <>
                                    <button className="net-btn green-bg green-shadow pl-4 pr-4" disabled>{t('sign_up')} <i className="ft-arrow-right ml-2"></i></button>
                                </>} 
                            </div>
                        </form>
                        </>}

                        {user_mobile_confiramtion && <>      
                            <div className="text-center mb-5"> {t('_confirm_call_number_0')} {UserNumber}, {t('_confirm_call_number_1')} 
                                <span className="text-muted"> 060 XX <span className="text-danger">XXXX</span></span>
                            </div>
                            <form onSubmit={SubmitCode}>
                                <div className="form-group mb-3 d-inline-flex col-sm-12">
                                <InputMask
                                    required
                                    autoFocus
                                    mask="9999"
                                    className='form-control b-radius p-2 pl-4 pr-4 text-center fw-bold ls-30 mb-3'
                                    value={auth_code} 
                                    onChange={e => setAuth_code(e.target.value.replace(/\D/,''))}
                                />
                                </div>
                                <div className="col-sm-12 mx-auto pt-0 mb-2 text-center">
                                {error_timer ? 
                                    <>
                                    <div className="text-danger mb-4">{t('_confirm_call_number_error_desc_')}</div>
                                    <button className="net-btn green-bg green-shadow pl-4 pr-4" disabled>
                                    {t('_wait')} {hours > 0 && timeDigits(hours) + ':'}{minutes > 0 && timeDigits(minutes) + ':'}{seconds && timeDigits(seconds)}
                                    </button></>
                                    :
                                    <>
                                    <div className="col-lg-6 col-md-6 col-sm-10 mx-auto pt-3 mb-2 text-center">
                                        <button onSubmit={SubmitCode} className="net-btn green-bg green-shadow pl-4 pr-4" disabled={auth_code && auth_code.length<= 3 ? true : false}>
                                        {t('_confirm_')}  {loading ? <FiLoader className="ml-2 spin"/> : <FiArrowRight className="ml-2"/>}</button>
                                    </div>
                                </>}
                                </div>

                            </form>
                        </>
                        }

                        {successForm ? 
                            <div className='col-sm-12 mt-5 mb-0'>
                                <div className='col-12'>
                                    <div className='d-flex justify-content-center align-items-center' style={{ height: '150px' }}>
                                        <div className='green-bg text-white d-flex justify-content-center align-items-center' style={{height:"70px", width:"70px", borderRadius: '100%' }}>
                                            <FiCheck className='display-5'/>
                                        </div>
                                    </div>
                                    <div className='green-text text-center mt-3 mb-5'>
                                        <div>{t('_thank_you_0')}</div>
                                        <div>{t('_thank_you_1')}</div>
                                    </div>
                                </div>
                            </div> 
                        :null}

                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
