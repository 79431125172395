import React, {useState, useEffect} from 'react';
import { FaCcVisa, FaCcMastercard, FaTrash, FaCheckCircle } from "react-icons/fa";
import { SiAmericanexpress } from "react-icons/si";
import { FiCreditCard } from "react-icons/fi";
import { BsFillCreditCard2BackFill } from "react-icons/bs";
import { IoWalletSharp } from "react-icons/io5";
import EmptyCard from '../Users_components/EmptyCard';
import { toastDanger, toastSuccess } from '../alerts/Notifications';
import Modal from 'react-bootstrap/Modal'
import CardRemove from '../Modals/CardRemove';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { BASE, CARDSUPDATE, PAYMENT_METHODS } from '../../Requests';


export default function UI_UserCards(props) {
    const { t } = useTranslation();
    const key = localStorage.getItem('e_')
    const card_class_active = "col-sm-12 card credit_card active d-flex align-items-center justify-content-between b-radius b-shadow mb-3 pt-2 pb-2 pl-4 pr-4"
    const card_class = "col-sm-12 card credit_card d-flex align-items-center justify-content-between b-radius b-shadow mb-3 pt-2 pb-2 pl-4 pr-4"

    const [ data, setData ] = useState(null);
    const [ show, setShow ] = useState(false);
    const [ card, setCard ] = useState(true);

    useEffect(() => {
      if(data === null){
        getMethods()
      }
    }, [data])
  
    const handlePlanShow = (e) => {
        setCard(e)
        setTimeout(() => {
            setShow(!show)
        }, 100);
    };
    const handleClose = () => {
        setShow(!show); 
    };

    const getMethods = () => {
        axios({
            url: BASE+PAYMENT_METHODS,
            method: 'GET',
            headers: { Authorization: `Bearer ${key}` },
            credentials: "same-origin",
        }).then((res) => {
            setData(res.data)
        }).catch((err)=> {}).finally()
    }

    const handledefault = (e) => {
        axios({
            url: BASE+CARDSUPDATE+e+'/',
            method: 'PATCH',
            headers: { Authorization: `Bearer ${key}` },
            credentials: "same-origin",
        }).then((res) => {
            toastSuccess('Payment method successfully added as default')
            getMethods()
        }).catch((err)=> {
            toastDanger('Sorry something went wrong')
        })
    }

    return (
        <>
    <div className="col stretch-card mb-3">
      <div className="card border-0 b-radius b-shadow p-4 ">
        <div className="col d-flex align-items-center text-uppercase">
            <FiCreditCard className='mr-3'/>
            <span>{t('_saved_pay_methods_')}</span>
        </div>
        <hr className="mt-3 mb-4"/>
        <div className='col-sm-12 p-0  pb-2'>
          <div className="row">


        {data?.customer_pay_methods.length > 0 ? 
            data?.customer_pay_methods?.map((card, index) => {
                return (
                    <div key={index} role={"button"} className={card?.default ? card_class_active : card_class}>
                    <div className='col'>
                        {card?.source == '0' && 
                        <>
                            {card?.merchant_data.card_number.slice(0,4) >= 4000 && card?.merchant_data.card_number.slice(0,4) <= 4999 ?
                            <div className='card-wrapper' style={{background:"#289c97"}}>
                                < FaCcVisa/>
                                </div> : null} 
                            {card?.merchant_data.card_number.slice(0,4) >= 5000 && card?.merchant_data.card_number.slice(0,4) <= 5999 ?
                            <div className='card-wrapper' style={{background:"#456c8b"}}>< FaCcMastercard/></div> : null} 
                            {card?.merchant_data.card_number.slice(0,4) >= 3400 && card?.merchant_data.card_number.slice(0,4) <= 3800 ?
                            <div className='card-wrapper' style={{background:"#2f6d9e"}}>< SiAmericanexpress/></div> : null} 
                            {card?.merchant_data.card_number.slice(0,4) >= 6000 && card?.merchant_data.card_number.slice(0,4) <= 9999 ?
                            <div className='card-wrapper' style={{background:"#8d9fac"}}>< BsFillCreditCard2BackFill/></div> : null} 
                        </>
                        }
                        {card?.source == '1' && 
                            <div className='card-wrapper' style={{background:"#f36e2f"}}><IoWalletSharp/></div> 
                        }
                        {card.default &&
                            <div className='default'>
                                <FaCheckCircle/>
                                <span>{t('_default_')}</span>
                            </div>
                        } 
                    </div> 

                    <div className='col d-flex justify-content-end'>
                        {card?.source == '0' ? 
                        <div className='text-end d-grid'>
                            <div className='h6 small mb-0'>{card?.merchant_data.card_holder} </div>
                            <div className='h5 m-0' style={{letterSpacing: "2px"}}>{card?.merchant_data.card_number} </div>
                            <div className='m-0 small'><span className='small mr-2'>{t('_valid_')}</span>{card?.merchant_data.expiration}</div>
                        </div>
                    : 
                    <div className='text-end d-grid'>
                        <div className='h6 small mb-1'>Telcel ID:</div>
                        <div className='h5 m-0' style={{letterSpacing: "2px"}}>{card?.merchant_data.telcell_id} </div>
                        <div className='m-0 small'><span className='small mr-2'>{""}</span>{""}</div>
                    </div>
                    }
                </div>
                <div className={"cards_wrapper text-white"} style={{zIndex:'9'}}>
                    <button type='button' className='btn p-2' onClick={(e) => handledefault(card.id, e)}> {t('_make_default_')} </button>
                    <button type='button' className='btn p-2'> |</button>
                    <button type='button' className='btn p-2' onClick={() => handlePlanShow(card.id)}> {t('_remove_')} <FaTrash className='small ml-2'/></button>
                </div>
            </div>
            )}
        )
        : 
            <EmptyCard desc={t('_dont_have_any_cards_')}/>
        }

        <Modal show={show} onHide={handleClose}  size="md" centered={true} backdrop='static' keyboard={false}>
            <CardRemove setShow={setShow} getMethods={getMethods} card_id={card}/>
        </Modal>
        
        </div>
        </div>
      </div>
    </div>     
        </> 
  )
}
