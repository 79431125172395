import axios from 'axios'
import { BASE, CUSTOMER_UPDATE } from '../Requests'
import { toastSuccess, toastDanger } from '../components/alerts/Notifications'; 

export const UserInfoPach = (props) => {
    const key = localStorage.getItem("e_");
    axios({
        method: "PATCH",
        url: BASE+CUSTOMER_UPDATE,
        data:{
            full_name: props.name,
        },
        headers: { Authorization: `Bearer ${key}` },
        credentials: "same-origin",
    }).then( res => {
        if(res.status === 200){
            toastSuccess('Changes are saved successfully')
        }
    }).catch( err => {
        if(err.status !== 200){
            toastDanger('Something went wrong! please ty again later')
        }
    })
}