import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = ({ allowed }) => {
    const location = useLocation();
    const key = JSON.parse(localStorage.getItem("key"));
    return(<>
        {key ? 
            key[1] === allowed ? <Outlet/> : <Navigate to="/" state={{ from: location }} replace /> 
            :
            allowed === true ? <Outlet/> : <Navigate to="/" state={{ from: location }} replace /> 
        }
    </>
    );
}

export default RequireAuth;