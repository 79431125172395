import React, { useState } from 'react'
import axios from 'axios';
import { BASE, CARDSDELETE } from '../../Requests';
import Modal from 'react-bootstrap/Modal'
import { toastSuccess, toastDanger } from '../alerts/Notifications'; 
import { FiLoader } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

export default function CardRemove(props) {
    const { t } = useTranslation();

    const key = localStorage.getItem('e_')
    const card_id = props?.card_id
    const [ loading, setLoading ] = useState(false)

    const handleremove = () => {
        setLoading(!loading)
        axios({
            url: BASE+CARDSDELETE+card_id+'/',
            method: 'DELETE',
            headers: { Authorization: `Bearer ${key}` },
            credentials: "same-origin",
        }).then((res) => {
            toastSuccess(t('_toaster_success_remove_payment'));
            props.getMethods()
            setTimeout(() => {
                props.setShow(false);
            }, 500);
        }).catch((err)=> {
            toastDanger(t('_toaster_danger_something_wrong'))
            props.getMethods()
            setTimeout(() => {
                props.setShow(true)
            }, 500);
        }) 
    }

  return (
    <div className='col-sm-12 b-radius overflow-hiden b-shadow bg-white p-0'>
    <Modal.Body>
        <div className="row p-0 m-0 ">
            <div className="col-lg-12 p-4">
                <div className='pt-3 pb-3 text-center'>
                    <div className='col-sm-12 pb-3'>{t('_remove_payment_method_desc')}</div>
                    { loading && <FiLoader className="fs-3 icon animated infinite spin slow"/> }
                </div>
            </div>
        </div>
        <div className='text-center justify-content-center border-top pt-4 pb-3'>
            <button className='net-btn orange-bg orange-shadow pl-4 pr-4 mr-3' onClick={(e) => props.setShow(false)}>{t('_go_back')}</button>
            <button className='net-btn green-bg green-shadow pl-4 pr-4 mr-3' onClick={(e) => handleremove(e)}>{t('_remove')}</button>
        </div>
    </Modal.Body>
</div>
  )
}
