import React from 'react';
import arca_logo from '../../assets/images/credit_cards/arca_logo.png' 
import mastercard_logo from '../../assets/images/credit_cards/mastercard_logo.png' 
import American_Express_logo from '../../assets/images/credit_cards/American_Express_logo.png' 
import visa_logo from '../../assets/images/credit_cards/visa_logo.png' 
import telcell_logo from '../../assets/images/credit_cards/telcell.png' 
import {alt} from '../../Static'
import { useTranslation } from 'react-i18next';

export default function Landing_payments() {
    const { t } = useTranslation();
  return <>
            <div className="col-sm-12 col-xs-12 mt-2 mb-2 p-0 pb-4">
              <div className="container pt-4 pb-2">
                  <div className="card b-radius b-shadow border-0 mb-4 p-5">
                      <div className="row">
                          <div className="col-sm-12 col-xs-12 text-muted pb-3 mb-3">
                              <h4 className="w-100 text-center pt-3 pb-5">{t('payment_options')}</h4>
                              <div className="col-md-7 mx-auto text-center justify-content-center">
                                  <img className="col-sm-2 col-xs-2 p-2 b-radius" src={telcell_logo} alt={alt}/>
                                  <img className="col-sm-2 col-xs-2 p-2 b-radius" src={arca_logo} alt={alt}/>
                                  <img className="col-sm-2 col-xs-2 p-2 b-radius" src={mastercard_logo} alt={alt}/>
                                  <img className="col-sm-2 col-xs-2 p-2 b-radius" src={American_Express_logo} alt={alt}/>
                                  <img className="col-sm-2 col-xs-2 p-2 b-radius" src={visa_logo} alt={alt}/>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </>;
}
