import React, { useState, useEffect } from 'react'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { FaUserShield, FaUserSecret, FaListUl, FaExchangeAlt, FaEdit, FaSave } from 'react-icons/fa'
import { AbonementInfoPach } from '../../hooks/AbonementInfoPach';
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next';


export default function UserInfoModalContent(props) {
    const { t } = useTranslation();
    const [ userinfo, setUserinfo ] = useState()
    const [ editPassword, setEditPassword ] = useState(false)
    const [ editPin, setEditPin ] = useState(false)
    const [ editDescription, setEditDescription ] = useState(false)
    const [ editProlong, setEditProlong ] = useState(false)
    
    const [ userAbonementID, setUserAbonementID ] = useState()
    const [ nPassword, setPassword ] = useState()
    const [ nPin, setPin ] = useState()
    const [ nDescription, setDescription ] = useState()
    const [ nProlong, setProlong ] = useState()

    useEffect(() => {
      if (props.data !== undefined){
          setUserinfo(props.data);
          setUserAbonementID(props.abonement)
          setPassword(props.data.password)
          setPin(props.data.parent_code)
          setDescription(props.data.full_name)
          setProlong(props.data.prolong)
      }
    }, [props.data])

    const handleSend = (e) => {
        e.preventDefault()
        const data = { id: userAbonementID, password: nPassword, pin: nPin, description: nDescription}
        AbonementInfoPach(data)
    }
    const handleSwitch = (e) => {
        const data = { id: userAbonementID, prolong: e.target.checked }
        AbonementInfoPach(data)
        setProlong(e.target.checked)
    }

  return (
    <div className='col-sm-12 b-radius overflow-hiden b-shadow bg-white p-0'>
    <Modal.Body>

    <div className="row p-0 m-0 ">
        <div className="col-lg-12 p-4">
            <div className='pt-3 pb-3 text-center'>
                <form className='col-sm-12 p-1'>

                    <div className="card d-flex justify-content-between b-radius b-shadow mb-3 pl-2 pr-4">
                        <div className='d-sm-block d-lg-flex d-md-flex align-items-center pl-4 pr-4 pb-0 pt-0'>
                            <FaUserShield className='h5 mr-3 mt-1'/> <span className='mr-3'>{t('_user_settings_pass')}: </span>
                            {editPassword === true ? 
                            <input type={'text'} value={nPassword} onChange={e => setPassword(e.target.value)} className="green-text border-0 border-bottom"/>
                            : 
                            <div className="m-0">{nPassword}</div> 
                            }
                        </div>
                        <button id={userAbonementID} 
                            onClick={
                                !editPassword ? (e) => {e.preventDefault(); setEditPassword(!editPassword)} : (e) => {handleSend(e); setEditPassword(!editPassword)}
                            } 
                            className='btn text-start text-muted mt-0 p-0'>
                            {!editPassword ? 
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('_user_settings_edit_pass')}</Tooltip>}>
                                <div><FaEdit/></div>
                            </OverlayTrigger> 
                            :
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('_user_settings_save')}</Tooltip>}>
                                <div><FaSave/></div>
                            </OverlayTrigger>}
                        </button>
                    </div>

                    <div className="card d-flex justify-content-between b-radius b-shadow mb-3 pl-2 pr-4">
                        <div className='d-sm-block d-lg-flex d-md-flex align-items-center pl-4 pr-4 pb-0 pt-0'>
                            <FaUserSecret className='h5 mr-3 mt-1'/> <span className='mr-3'>{t('_user_settings_pin')}: </span>
                            {editPin === true ? 
                            <input type={'text'} value={nPin} onChange={e => setPin(e.target.value)} className="green-text border-0 border-bottom"/>
                            : 
                            <div className="m-0">{nPin}</div> 
                            }
                        </div>
                        <button id={userAbonementID} 
                            onClick={!editPin ? (e) => {e.preventDefault(); setEditPin(!editPin)} : (e) => {handleSend(e); setEditPin(!editPin)}} 
                            className='btn text-start text-muted mt-0 p-0'>
                            {!editPin ? 
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('_user_settings_edit_pin')}</Tooltip>}>
                                <div><FaEdit/></div>
                            </OverlayTrigger> 
                            :
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('_user_settings_save')}</Tooltip>}>
                                <div><FaSave/></div>
                            </OverlayTrigger>}
                        </button>
                    </div>

                    <div className="card d-flex justify-content-between b-radius b-shadow mb-3 pl-2 pr-4">
                        <div className='d-sm-block d-lg-flex d-md-flex align-items-center pl-4 pr-4 pb-0 pt-0'>
                            <FaListUl className='h5 mr-3 mt-1'/> <span className='mr-3'>{t('_user_settings_desc')}: </span>
                            {editDescription === true ? 
                            <input type={'text'} 
                            value={nDescription} onChange={e => setDescription(e.target.value)} className="green-text border-0 border-bottom"
                            placeholder={nDescription ? nDescription : t('_user_settings_add_desc')}
                            />
                            : 
                            <div className="m-0">{nDescription}</div> 
                            }
                        </div>
                        <button id={userAbonementID} 
                            onClick={!editDescription ? (e) => {e.preventDefault(); setEditDescription(!editDescription)} : (e) => {handleSend(e); setEditDescription(!editDescription)}} 
                            className='btn text-start text-muted mt-0 p-0'>
                            {!editDescription ? 
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('_user_settings_edit_desc')}</Tooltip>}>
                                <div><FaEdit/></div>
                            </OverlayTrigger> 
                            :
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('_user_settings_save')}</Tooltip>}>
                                <div><FaSave/></div>
                            </OverlayTrigger>}
                        </button>
                    </div>

                    <div className="card d-flex justify-content-between b-radius b-shadow mb-3 pl-2 pr-4">
                        <div className='d-sm-block d-lg-flex d-md-flex align-items-center pl-4 pr-4 pb-0 pt-0'>
                            <FaExchangeAlt className='h5 mr-3 mt-1'/> <span className='mr-3'>{t('_user_settings_prolong')}: </span>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" id="prolong" 
                                defaultChecked={nProlong}
                                onClick={(e) => handleSwitch(e)}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
        <div className='text-center justify-content-center border-top pt-4 pb-3'>
            <button className='net-btn orange-bg orange-shadow pl-4 pr-4 mr-3' onClick={(e) => props.setUserInfoModalShow(false)}>{t('_close_')}</button>
        </div>
    </Modal.Body>
</div>
  )
}
