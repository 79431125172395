import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { BASE, TRANSACTIONS } from '../../Requests';
import Modal from 'react-bootstrap/Modal'
import {FiTerminal, FiClock, FiAlertTriangle, FiCircle, FiCheckCircle, FiAlertCircle, FiX } from 'react-icons/fi'
import { useTranslation } from 'react-i18next';

export default function AllTransactions(props) {
    const key = localStorage.getItem("e_");
    const [Items, setItems] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState(BASE+TRANSACTIONS);
    const [HasMore, setHasMore] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        loadMoreItems();
    }, []);
    
    function loadMoreItems() {
        setIsFetching(true);
        window.scrollTo(0, 0)
        axios({
        method: "GET",
        url: page,
        headers: { Authorization: `Bearer ${key}` },
        credentials: "same-origin",
        }).then((res) => {
            setItems(Items.concat(res.data.results));
            setPage(res.data.next);
            setHasMore(res.data.next !== null);
            setIsFetching(false);
        }).catch((e) => {
        });
    }

  return (
    <div className='col-sm-12 b-radius overflow-hiden b-shadow bg-white p-0'>
        <Modal.Body>
            <div className='col-sm-12 d-flex justify-content-between align-items-cente'>
                <div className="col d-flex justify-content-start align-items-center text-uppercase">
                    <FiTerminal className='mr-3'/>
                    <span>{t('_all_transactions_')}</span>
                </div>
                <div className="col d-flex justify-content-end align-items-center text-uppercase">
                    <button type="button" className={'btn'} onClick={() => props.setShow(false)}>
                        {t('_close_')} <FiX className='ml-2'/>
                    </button>
                </div>
            </div>
                <div className='col-sm-12'>
                    <hr className="mt-3 mb-4"/>


                    <div style={{maxHeight: 'calc(80vh - 280px)', overflowY: 'auto'}}>
                        {Items.map((data, i) => {
                            if (Items.length === i + 1) {
                                return (
                                    <div key={i} className='col-sm-12 p-0 border-bottom'>
                                        <div className="row m-0 pt-2 pb-2 p-0">
                                            <div className="col-6 d-flex">
                                                {data.processed === '0' ?  <span className='d-flex align-items-center mr-3 text-muted fs-5'><FiCircle/></span> : null}
                                                {data.processed === '1' ?  <span className='d-flex align-items-center mr-3 green-text fs-5'><FiCheckCircle/></span> : null}
                                                {data.processed === '2' ?  <span className='d-flex align-items-center mr-3 text-danger fs-5'><FiAlertTriangle/></span> : null}
                                                {data.processed === '3' ?  <span className='d-flex align-items-center mr-3 text-info fs-5'><FiClock/></span> : null}
                                                {data.processed === '4' ?  <span className='d-flex align-items-center mr-3 orange-text fs-5'><FiAlertCircle/></span> : null}
                                                <div className="d-grid">
                                                    <span className="fw-bold small">No {data.id}</span> 
                                                    <span className="small">{data.created}</span> 
                                                </div>
                                            </div>
                                            <div className="col-6 text-end">
                                                <div className="d-grid">
                                                    {data.processed === '0' ?  <div className="fw-bold text-muted">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                                    {data.processed === '1' ?  <div className="fw-bold green-text">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                                    {data.processed === '2' ?  <div className="fw-bold text-danger">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                                    {data.processed === '3' ?  <div className="fw-bold text-info">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                                    {data.processed === '4' ?  <div className="fw-bold orange-text">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                                    <div className="small m-0">{data.comment ? data.comment : (" ")}</div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else {
                                return  <div key={i} className='col-sm-12 p-0 border-bottom'>
                                            <div className="row m-0 pt-2 pb-2 p-0">
                                                <div className="col-6 d-flex">
                                                    {data.processed === '0' ?  <span className='d-flex align-items-center mr-3 text-muted fs-5'><FiCircle/></span> : null}
                                                    {data.processed === '1' ?  <span className='d-flex align-items-center mr-3 green-text fs-5'><FiCheckCircle/></span> : null}
                                                    {data.processed === '2' ?  <span className='d-flex align-items-center mr-3 text-danger fs-5'><FiAlertTriangle/></span> : null}
                                                    {data.processed === '3' ?  <span className='d-flex align-items-center mr-3 text-info fs-5'><FiClock/></span> : null}
                                                    {data.processed === '4' ?  <span className='d-flex align-items-center mr-3 orange-text fs-5'><FiAlertCircle/></span> : null}
                                                    <div className="d-grid">
                                                        <span className="fw-bold small">No {data.id}</span> 
                                                        <span className="small">{data.created}</span> 
                                                    </div>
                                                </div>
                                                <div className="col-6 text-end">
                                                    <div className="d-grid">
                                                        {data.processed === '0' ?  <div className="fw-bold text-muted">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                                        {data.processed === '1' ?  <div className="fw-bold green-text">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                                        {data.processed === '2' ?  <div className="fw-bold text-danger">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                                        {data.processed === '3' ?  <div className="fw-bold text-info">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                                        {data.processed === '4' ?  <div className="fw-bold orange-text">{data.amount} <span className="small">{t('_amd_')}</span></div>  : null}
                                                        <div className="small m-0">{data.comment ? data.comment : (" ")}</div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>;
                                        }
                                    })}
                                </div>
                            <div>
                        </div>
                    </div>
                    <div className='text-center justify-content-center border-top pt-4 pb-3'>
                    {HasMore ? 
                        <button className='net-btn green-bg green-shadow pl-4 pr-4 mt-3 mb-3' onClick={loadMoreItems}>
                            {isFetching ? t('_fatch_more_') : t('_load_more_')}
                        </button>
                        : <div className='pl-4 pr-4 mt-3 mb-3'>{t('_this_is_all_')}</div>}
                    </div>
        </Modal.Body>
    </div>
  )
}
