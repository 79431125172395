import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
  return <>
    <footer className="footer p-3 mt-auto text-center bg-white b-shadow">
      © {t("_footer_copyright")} - {new Date().getFullYear()} {t("_footer_copyright_line")}
    </footer>
  </>;
}
