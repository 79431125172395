import React, { useState, useEffect } from 'react'
import { BASE, PAYMENT_METHODS, PROLONG } from '../../Requests';
import { FiCheck, FiInfo } from "react-icons/fi";
import Modal from 'react-bootstrap/Modal'
import axios from 'axios';
import {toastDanger, toastSuccess, toastWarning } from '../alerts/Notifications';
import { FaCcVisa, FaCcMastercard } from "react-icons/fa";
import { SiAmericanexpress } from "react-icons/si";
import { BsFillCreditCard2BackFill } from "react-icons/bs";
import { IoWalletSharp } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
 
export default function PaymentWindow(props) {
    const { t } = useTranslation();

    const key = localStorage.getItem("e_");

    const card_class_active = "selected-card col-sm-12 card credit_card active d-flex align-items-center justify-content-between b-radius b-shadow mb-3 pt-2 pb-2 pl-4 pr-4 position-relative overflow-hidden"
    const card_class = "col-sm-12 card credit_card d-flex align-items-center justify-content-between b-radius b-shadow mb-3 pt-2 pb-2 pl-4 pr-4 position-relative overflow-hidden"

    const [ state, setState ] = useState(props?.data)
    const [ data, setData ] = useState(null)
    const [ isActive, setIsActive ] = useState(false)
    const [ isChecked, setChecked ] = useState(false)
    const [ language, setLanguage ] = useState('hy')
    const [ cardID, setCardID ] = useState()
    const [ sourceID, setSourceID ] = useState()
    const [ merchantID, setMerchantID ] = useState()
    const [ merchantPayment, setMerchantPayment ] = useState(false)
    const [ telcellID, setTelcellID ] = useState('')
    const [ merchantCardForm, setMerchantCardForm ] = useState(false)
    const [ disabled, setDisabled ] = useState(true)

    const [ telcellForm, setTelcellForm ] = useState(false)
    const [ paymentForm, setPaymentForm ] = useState(true)

    const getMethods = () => {
        axios({
            url: BASE+PAYMENT_METHODS,
            method: 'GET',
            headers: { Authorization: `Bearer ${key}` },
            credentials: "same-origin",
        }).then((res) => {
            setData(res.data)
        }).catch((err)=> {}).finally()
    }

    useEffect(() => {
        if(props.data) {
            setState(props.data)
        }
    }, [state])
    useEffect(() => {
        if(data === null) {
            getMethods()
        }
    }, [data])

    useEffect(() => {
        switch(navigator.language) {
            case navigator.language === 'en-US': 
                setLanguage('en')
                break
            case navigator.language === 'ru-RU': 
                setLanguage('ru')
                break
            case navigator.language === 'hy-HY': 
                setLanguage('hy')
                break
            default: setLanguage('en'); break
        }
    }, [])

    const proceedToPayment = (e) => {
        setDisabled(true)
        axios({
            method: 'POST',
            url: BASE+PROLONG,
            headers: { Authorization: `Bearer ${key}` },
            credentials: "same-origin",
            data: {
                'source': sourceID, 
                'save_card': isChecked, 
                'tel_cell': telcellID, 
                'lang': language, 
                'tariff': props?.data.plan, 
                'abonement': localStorage.getItem('i_'), 
                'promotion': props?.data.range, 
                'merchant': merchantID
            }
        }).then((res) => {
            setTimeout(() => {
                if(res.data?.source == 0 && res.data?.redirect_url.length > 1){
                    window.open(res.data.redirect_url)
                }
                if(res.data?.source == 0 && res.data?.redirect_url.length < 1){
                    setMerchantCardForm(true)
                    setPaymentForm(false)
                }
                if(res.data?.source == 1 && res.data?.redirect_url.length < 1){
                    setTelcellForm(true);
                    setPaymentForm(false)
                }
                props.fetch()
            }, 200);
            localStorage.removeItem('i_d')
        }).catch((err) => {
            if(err?.response.data.source === '1' && err?.response.data.redirect_url < 1){
                toastWarning(t('_toaster_warning_telcel_id'));
            }
            if(err?.response.data.tel_cell){
                toastDanger(t('_toaster_danger_telcel_wrong_id'));
            }
            if(err?.response.data.error_message){
                toastDanger(t('_toaster_danger_telcel_doesnt_exist_id'));
            }
            })
        }

    return (

        <div className='col-sm-12 b-radius overflow-hiden b-shadow bg-white p-0'>
        <Modal.Body>

            {telcellForm && 
            <div className='col-sm-12 p-3'>
                <div className='mt-5 mb-5 text-center'>
                    <div className='text-muted'>{t('_payment_telcel_notification_title')}</div>
                        <div className='text-muted'>{t('_payment_telcel_notification_desc')}
                            <span className='green-text fsw-bold ml-2 mr-2'>{telcellID}</span>{t('_payment_telcel_notification_account')}
                        </div>
                    </div>
                    <div className='text-center justify-content-center border-top pt-4 pb-3'>
                        <button className='net-btn green-bg green-shadow pl-4 pr-4 mr-3' 
                        onClick={(e) => {props.setShow(false); props.setPaymentModal(false)}}
                        >{t('_close_')}  <FiCheck className='ml-2'/></button>
                    </div>
                </div>}

            {merchantCardForm && 
            <div className='col-sm-12 p-3'>
                <div className='mt-5 mb-5 text-center'>
                    <div className='text-muted'>{t('_thank_you_')} </div>
                        <div className='text-muted'>{t('_payment_success_merchant_')} </div>
                    </div>
                    <div className='text-center justify-content-center border-top pt-4 pb-3'>
                        <button className='net-btn green-bg green-shadow pl-4 pr-4 mr-3' 
                        onClick={(e) => {
                            setMerchantCardForm(false);
                            setPaymentForm(false)
                            toastSuccess(t('_toaster_success_payment_successfull'))
                            props.setShow(false); 
                            props.setPaymentModal(false)
                        }}
                        >{t('_close_')} <FiCheck className='ml-2'/></button>
                    </div>
                </div>}

            {paymentForm && <>
            
            <div className="row mb-0 pl-3 pr-3 pt-2">
                <div className="border-0">
                    {props?.length > 0 ? 
                    null
                    : 
                    <>
                    {data?.customer_pay_methods.length >0 ? <>
                        <div className="col-sm-12 d-inline-flex align-items-center h6">
                            <FiInfo className='mr-3'/>
                            <span>{t('_merchant_default_payments_')}</span>
                        </div>
                        <hr className="mt-3 mb-4"/>
                        </>
                    : null}
                            
                        <div className="row p-0">
                        {data ? 
                            data?.customer_pay_methods?.map((card, index) => {
                                return (
                                
                            <div key={index} className='position-relative'>
                                <a key={index} role={"button"} className={merchantID === card.id  ? card_class_active : card_class}>
                                    {merchantID === card.id ? <div className='default'><FiCheck className='svg'/></div> : null} 
                                    <div key={index} className='col'>
                                        {card?.source == '0' && 
                                        <>
                                            {card.default && <div className='default'><span>{t('_default_')}</span></div>} 
                                            {card?.merchant_data.card_number.slice(0,4) >= 4000 && card?.merchant_data.card_number.slice(0,4) <= 4999 ?
                                            <div className='card-wrapper' style={{background:"#289c97"}}>< FaCcVisa/></div> : null} 
                                            {card?.merchant_data.card_number.slice(0,4) >= 5000 && card?.merchant_data.card_number.slice(0,4) <= 5999 ?
                                            <div className='card-wrapper' style={{background:"#456c8b"}}>< FaCcMastercard/></div> : null} 
                                            {card?.merchant_data.card_number.slice(0,4) >= 3400 && card?.merchant_data.card_number.slice(0,4) <= 3800 ?
                                            <div className='card-wrapper' style={{background:"#2f6d9e"}}>< SiAmericanexpress/></div> : null} 
                                            {card?.merchant_data.card_number.slice(0,4) >= 6000 && card?.merchant_data.card_number.slice(0,4) <= 9999 ?
                                            <div className='card-wrapper' style={{background:"#8d9fac"}}>< BsFillCreditCard2BackFill/></div> : null} 
                                        </>
                                        }
                                        {card?.source == '1' && <div className='card-wrapper' style={{background:"#f36e2f"}}><IoWalletSharp/></div>}
                                        {card.default && <div className='default'><span>{t('_default_')}</span></div>} 
                                    </div>

                                    <div className='col d-flex justify-content-end'>
                                    {card?.source == '0' ? 
                                        <div className='text-end d-grid'>
                                            <div className='h6 small mb-0'>{card?.merchant_data.card_holder} </div>
                                            <div className='h5 m-0' style={{letterSpacing: "2px"}}>{card?.merchant_data.card_number} </div>
                                            <div className='m-0 small'><span className='small mr-2'>{t('_valid_')}</span>{card?.merchant_data.expiration}</div>
                                        </div>
                                    : 
                                        <div className='text-end d-grid'>
                                            <div className='h6 small mb-1'>Telcel ID:</div>
                                            <div className='h5 m-0' style={{letterSpacing: "2px"}}>{card?.merchant_data.telcell_id} </div>
                                            <div className='m-0 small'><span className='small mr-2'>{""}</span>{""}</div>
                                        </div>
                                    }
                                    </div>
                                    <div className={"cards_wrapper_select"} style={{zIndex:'9', justifyContent: 'center'}}>
                                        <button type='button' className='btn p-2' 
                                            onClick={() => {
                                                setTelcellID(card?.merchant_data.telcell_id)
                                                setMerchantID(card.id)
                                                setSourceID(card.source)
                                                setMerchantPayment(!merchantPayment)
                                                setIsActive(false)
                                                setChecked(undefined);
                                                setCardID('') 
                                                setDisabled(false)
                                            }}
                                            > {t('_select_method')}
                                        </button>
                                    </div>
                                </a>
                            </div>
                                )}
                            )
                            : null}
                        </div>
                    </>
                    }
                    <div className="col-sm-12 d-inline-flex align-items-center h6 mt-3 mb-2">
                        <i className='ft-info mr-3'></i>
                        <span>{t('_select_pay_method_')}</span>
                    </div>
                    <hr className="mt-0 mb-4"/>

                    <div className="row mb-0">
                        {data?.pay_methods?.map((card, index) => 
                        <div key={index} className='col-sm-6 col-xs-12 mb-3 strech-card' 
                            onClick={() => {
                                setIsActive(true); 
                                setTelcellID('')
                                setMerchantID('')
                                setChecked(false); 
                                setCardID(card.source)
                                setSourceID(card.source)
                                setDisabled(false)
                            }
                            }
                        >
                            <div className={card.source === cardID ? 'card b-radius b-shadow p-0 text-center pointer' : 'card bg-white b-radius b-shadow border-0 p-0 text-center pointer'}>
                                <div className='d-inline-flex align-items-center'>
                                    <img src={card.image} style={{width: '100px'}}/>
                                    {card.source == cardID && 
                                    <div className='default'><FiCheck className='relative-svg ml-2'/></div>
                                    }
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        <div>
            <div className='col-sm-12 mb-3'>
                {cardID == 0 ? 

isActive && 
                    <div className='col-12'>
                        <div className="form-check form-switch d-flex justify-content-center align-items-center">
                            <input className="form-check-input mr-3" type="checkbox" role="switch" id="arca" 
                            onChange={(e) => {setChecked(e.target.checked); setIsActive(true)}}
                            defaultChecked={isChecked}
                            />
                            <label className="form-check-label" htmlFor="arca">{t('_save_method')}</label>
                        </div>
                    </div>


                : null }
                {cardID == 1 ? 

isActive && 
                <div className='col-sm-12 mb-5'>
                  <div  className='col-11 mx-auto text-center'>
                      <div className="form-floating mb-3 col-sm-12">
                          <input type="number" className="form-control b-radius b-shadow border-0" id="telcelID" placeholder="Your TelCell id"
                            value={telcellID.replace('s/[^0-9]*//g')}
                            onChange={(e) => setTelcellID(e.target.value.replace('s/[^0-9]*//g'))}
                          />
                          <label htmlFor="telcelID">{t('_method_telcel_id')}</label>
                      </div>
                      <div className="form-check form-switch d-flex justify-content-center align-items-center">
                          <input className="form-check-input mr-3" type="checkbox" role="switch" id="telcel" 
                          onChange={(e) => setChecked(e.target.checked)}
                          defaultChecked={isChecked}/>
                          <label className="form-check-label" htmlFor="telcel">{t('_save_method')}</label>
                      </div>
                  </div>
                </div>
                

                : null }
            </div>
        </div>
            <div className='text-center justify-content-center border-top pt-4 pb-3'>
                <button className='net-btn orange-bg orange-shadow pl-4 pr-4 mr-3' 
                    onClick={(e) => {
                        props.setShow(true); 
                        props.setPaymentModal(false)
                        }}>{t('_go_back')}</button>
                <button className='net-btn green-bg green-shadow pl-4 pr-4 mr-3' 
                    onSubmit={proceedToPayment} 
                    disabled={

                        cardID == 1 && telcellID?.length < 1 || disabled
                    }
                    onClick={
                        (e) => proceedToPayment(e)
                        }>{t('_pay_')} {localStorage.getItem('p_')} {t('_amd_')}</button>
            </div>
            </>}
        </Modal.Body>
    </div>
    )
}
