import axios from 'axios'

export const BASE = `https://api.arpinet.tv/api/v1`
export const REG = `/accounts/account/register/`
export const REGCONFIRM = `/accounts/account/registerConfirm/`
export const AUTH = `/accounts/account/auth/` 
export const CONFIRM = `/accounts/account/authConfirm/`
export const REFRESHTOKEN = `/accounts/token/refresh/`
export const CUSTOMER = `/accounts/customer/info/`
export const CUSTOMER_UPDATE = `/accounts/customer/info/update/`
export const PAYMENT_METHODS = `/billing/payment/methods/`
export const SUBSCRIPTIONS = `/billing/account/subscriptions/`
export const REMOVESUBSCRIBTION = `/billing/account/`
export const CHANGEMAIL = `/accounts/customer/changeEmail/`
export const ACTIVATEMAIL = `/accounts/customer/emailActivate/`
export const ABONEMENTINFO = '/accounts/abonement/info/'
export const ABONEMENTINFOUPDATE = '/accounts/abonement/info/update/'
export const CARDSUPDATE = '/billing/binding/update/'
export const CARDSDELETE = '/billing/binding/delete/'
export const TRANSACTIONS = '/billing/customer/transactions/'

export const PROLONG = '/billing/payment/'




export const axiosPrivate = axios.create({
    headers: {'Content-Type': 'application/json'},
    credentials: "same-origin",
})