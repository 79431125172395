import { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import ToastNotification from './alerts/ToastNotification'
import Footer from './Footer'
import Navbar from './Navbar'
import ConnectionStatus from './UI/ConnectionStatus'
const Layout = () => {
    const [ status, setStatus ] = useState()

    useEffect(() => {
        if(navigator.onLine){
            setStatus(true)
          }else{
              setStatus(false)
          }
    })

    window.addEventListener("offline", ()=> setStatus(false));
    window.addEventListener("online",()=> setStatus(true));
    return (
        <>
        <div className="d-flex flex-column h-100">
            <div className="content-wrapper justify-content-center">
                <div className="col-sm-12 col-xs-12 p-0">
                    <div className="row m-0 p-0 mt-5">
                    <Navbar/>
                        {status === true && <Outlet/>}
                        {status === false && <ConnectionStatus/>}
                    </div>
                </div>
            </div>
        </div>
        <ToastNotification/>
        <Footer/>
        </>
    )
}

export default Layout