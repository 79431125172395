import React, { useState, useEffect } from 'react';
import {FaUserCircle, FaKey, FaPowerOff} from 'react-icons/fa'
import {FiMenu} from 'react-icons/fi'
import {BiShield} from 'react-icons/bi'
import logo from '../assets/logo.png';
import {alt} from '../Static'
import hy from '../assets/images/lng/hy.svg'
import en from '../assets/images/lng/en.svg'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export default function Navbar(props) {
    const [ state, setState ] = useState(null)
    const key = JSON.parse(localStorage.getItem("key"))

    useEffect(() => {
        if(key) {
            if (key[1] === false)
            {
                setState(true)
            } else{
                setState(false)
            }
        }
    }, [state])

    const { t } = useTranslation();

	function handleClick(lang) {
		i18next.changeLanguage(lang)
	}

    return <>
            <nav className="navbar navbar-expand-sm bg-white b-shadow position-fixed top-0">
                <div className="container">
                    <Link to={state === true ? '/user/dashboard' : "/"} className="col-sm-4">
                        <img className="logo" src={logo} alt={alt}/>
                    </Link>
                    <Link className="navbar-toggler" to="#" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <FiMenu/>
                    </Link>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                    {state === true ? (
                        <>
                            <li className="nav-item">
                                <Link className="nav-link" to={state === true ? "/user/dashboard" : '/login'}><BiShield className=' mr-2'/>{t('Dashboard')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/logout"><FaPowerOff className=' mr-2'/>{t('log_out')}</Link>
                            </li>
                        </>
                        ) : <>
                            <li className="nav-item">
                                <Link className="nav-link" to="/registration"><FaUserCircle className=' mr-2'/>{t('sign_up')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/login"><FaKey className=' mr-2'/>{t('log_in')}</Link>
                            </li>
                        </>
                        }
                            <li className="nav-item d-flex align-items-center">
                                <button type="button" className="nav-link" onClick={() => handleClick('hy')}>
                                    <img src={hy} alt={alt}/>
                                </button>
                            </li>
                            <li className="nav-item d-flex align-items-center">
                                <button type="button"  className="nav-link" onClick={() => handleClick('en')}>
                                    <img src={en} alt={alt}/>
                                </button>
                            </li>
                    </ul>
                    </div>
                </div>
            </nav>
    </>;
}
