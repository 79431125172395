import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {BASE,CUSTOMER} from '../Requests'
import PaymentHistory from './Users_components/PaymentHistory';
import UserInfo from './Users_components/UserInfo';
import Loading from './UI/Loading';
import WelcomeCard from './Users_components/WelcomeCard'
import AccountInformation from './Users_components/AccountInformation';
import { useLocation, Navigate} from 'react-router-dom';
import useRefreshToken from '../hooks/useRefreshToken';
import UI_UserCards from './UI/UI_UserCards';

export default function UserLanding() {
  const token = useRefreshToken()
  const key = localStorage.getItem('e_')
  const key_all = localStorage.getItem('key')
  const credentials = "same-origin"
  
  const [ name, setName ] = useState()
  const [ nameStatus, setNameStatus ] = useState()
  const [ email, setEmail ] = useState()
  const [ emailStatus, setEmailStatus ] = useState()

  const [ info, setInfo ] = useState()
  const [ loading, setLoading] = useState(false)
  const location = useLocation();

  function fetchInfo(){
    axios.get(BASE+CUSTOMER, { headers: { Authorization: `Bearer ${key}` },}, credentials)
    .then((res) => {
        setInfo(res.data);
    }).catch((error) => {
      if(error.response.status === 401){
          localStorage.removeItem("key");
          localStorage.removeItem("e_");
          setTimeout(() => {
            <Navigate to="/login" state={{ from: location }} replace />
          }, 200);
      }
    }).finally(() => {})
  }


  useEffect(() => {
      setLoading(true);
      if(info === undefined && key && key_all){
        fetchInfo()
      }
  }, [info, key, loading])

  useEffect(() => {
    if(info?.full_name === null){ 
      setName(null); 
      setNameStatus(true) 
    } else if (info?.full_name !== null) {
      setName(info?.full_name); 
      setNameStatus(false)
    }
  })
  useEffect(() => {
    if(info?.profiles.email === null){ 
      setEmail(null); 
      setEmailStatus(false) 
    } else if (info?.profiles.email !== null) {
      setEmail(info?.profiles.email); 
      setEmailStatus(info?.profiles.status)
    }
  })

  
  return <>
    {info ?     
      <div className="col-12 p-0 mt-3">
        <div className="container mt-5">
          {/* {info?.welcome === false &&
            <div className="row match-height mb-3">
                <WelcomeCard data={{welcome: info?.welcome, name: name}} fetch={fetchInfo}/>
            </div>
          } */}

          <div className="row match-height mb-3">
              <AccountInformation data={info.abonements} fetch={fetchInfo}/>
          </div>
          
          <div className="row match-height mb-3">
            <div className='col-lg-5 col-md-6 col-sm-12 stretch-card'>
            <UserInfo data={{name: name, main_email: info?.email, email: email, name_status: nameStatus, email_status: emailStatus}} />
            <UI_UserCards/>
            </div>
            <div className='col-lg-7 col-md-6 col-sm-12 stretch-card'>
            <PaymentHistory data={info.transactions}/>
            </div>
          </div>
        </div>
      </div> 
  : <Loading/> }
  </>;
}
