import React from 'react';
import { Link } from 'react-router-dom';
import {alt} from '../../Static'
import inform from '../../assets/images/01_inform.svg'
import local from '../../assets/images/02_local.svg'
import entertaiment from '../../assets/images/03_entertaiment.svg'
import sport from '../../assets/images/04_sport.svg'
import kids from '../../assets/images/05_kids.svg'
import movies from '../../assets/images/06_movies.svg'
import music from '../../assets/images/07_music.svg'
import edu from '../../assets/images/08_edu.svg'
import { useTranslation } from 'react-i18next';

export default function Landing_service() {
    const { t } = useTranslation();
  return <>  
    <div className="col-sm-12 col-xs-12 p-0 position-relative mobile-tv-bg paralax">
        <div className="container pt-5 pb-5 ">
            <div className="card b-shadow b-radius border-0 article mt-5 mb-5 p-5 inner-section">
                <div className="row p-0 m-0">
                    <div className="col-sm-12 col-xs-12 text-muted pb-3 mb-3 p-0">
                        <h4 className="w-100 text-center pt-3 pb-5">{t("_channel_title_0")} <br/> {t("_channel_title_1")}</h4>
                        <div className="row p-0 m-0">
                            <div className="col-lg-3 col-md-6 col-sm-12 p-0 text-md-start text-sm-center text-xs-center">
                                <p><img src={inform} alt={alt} /> {t("_channel_0")} </p>
                                <p><img src={local} alt={alt} /> {t("_channel_1")} </p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 p-0 text-md-start text-sm-center text-xs-center">
                                <p><img src={entertaiment} alt={alt} /> {t("_channel_2")} </p>
                                <p><img src={sport} alt={alt} /> {t("_channel_3")} </p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 p-0 text-md-start text-sm-center text-xs-center">
                                <p><img src={kids} alt={alt} /> {t("_channel_4")} </p>
                                <p><img src={movies} alt={alt} /> {t("_channel_5")} </p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 p-0 text-md-start text-sm-center text-xs-center">
                                <p><img src={music} alt={alt} /> {t("_channel_6")} </p>
                                <p><img src={edu} alt={alt} /> {t("_channel_7")} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0">
                    <div className="col-lg-5 col-sm-12 col-xs-12 text-muted pricing p-0 stretch-card">
                        <div className="col-sm-12 col-xs-12 p-2 stretch-card">
                            <div className="card b-shadow b-radius border-0 green border-0 text-center">
                                <h3 className="orange-text">Mobile TV</h3>
                                <p className="small text-muted text-center w-100 mb-0 pb-0 mt-2 pt-2">{t("_plan_monthly")} </p>
                                <h3 className="mt-0 pt-0">3800</h3>
                                <div className="inet-info col-12 pt-3">
                                    <h1>3</h1>
                                    <ul className="till-wrapper pt-3">
                                        <li>{t("_plan_smart")}</li>
                                        <li>{t("_plan_device")}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-sm-12 col-xs-12 text-muted p-0 stretch-card">
                        <div className="col-sm-12 col-xs-12 p-2 stretch-card">
                            <div className="card b-shadow b-radius border-0 article border-0 text-center pricing ">
                                <h4 className="text-center mb-0 col-12 text-uppercase">{t("days_reg_")}</h4>
                                <h1 className="col-12 text-center pt-3 green-text pb-2">{t("days_")}</h1>
                                <h4 className="text-center mb-0 col-12 orange-text">{t("days_free")}</h4>
                                <div className="col-sm-12 col-xs-12 text-center p-0 pt-5 pb-2 mt-4">
                                    <Link className="net-btn green-bg green-shadow pr-3 pl-3 pt-2 pb-2 mb-4 mr-3" to="/login">{t("log_in_")}<i className="pl-2 ft-log-in"></i> </Link>
                                    <Link className="net-btn orange-bg orange-shadow pr-3 pl-3 pt-2 pb-2 ml-2 mb-3" to="/registration">{t('reg')}<i className="ft-corner-down-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </>;
}
