import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App'
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './i18next'
import './assets/css/bootstrap.css'
import './assets/fonts/calibri.css'
import './assets/css/animate.css'
import './assets/fonts/flag-icon-css/css/flag-icon.css'
import './assets/css/styles.css'
import 'jquery'
import 'popper.js'
import 'bootstrap'



const app = (
  <Suspense fallback={''}>
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/*" element={<App/>}/>
      </Routes>
    </AuthProvider>
  </BrowserRouter>
  </Suspense>
); 
ReactDOM.render(app, document.querySelector("#arpinet_tv"));