import React from 'react'
import { Toaster } from 'react-hot-toast';

export default function ToastNotification () {
  return (
    <Toaster 
        position="top-right" 
        containerStyle={{top: 90, left: 0, bottom: 0, right: 0}} 
        toastOptions={{
        style: {
            width: "100%",
            padding: '0',
            marginRight: '10px',
            color: '#fff',
            background: '#4e9d29'
            },
        }}
    />
  )
}
