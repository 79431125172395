import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { BASE, REMOVESUBSCRIBTION } from '../../Requests';
import Modal from 'react-bootstrap/Modal'
import { toastSuccess, toastDanger } from '../alerts/Notifications'; 
import { FiLoader } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

export default function RemoveModal(props) {
    const { t } = useTranslation();

    const [ state, setState ] = useState(props?.data)
    const [ loading, setLoading ] = useState(false)

    useEffect(() => {
        if(props.data) {
            setState(props.data)
        }
    }, [state])

    function removePlan(){
        setLoading(!loading)
        axios({
            method: "DELETE",
            url: BASE+REMOVESUBSCRIBTION+localStorage.getItem("i_")+'/subscription/'+localStorage.getItem("r_")+'/remove/',
            headers: { Authorization: `Bearer ${localStorage.getItem("e_")}` },
            credentials: "same-origin",
        }).then(res => {
            toastSuccess(t('_toaster_success_unsubscribe'));
            localStorage.removeItem("r_")
            props.loadPlans()
            setTimeout(() => {
                props.setShow(true); 
                props.setRemovePlanModal(false)
            }, 500);
        }).catch(err => {
            toastDanger(t('_toaster_danger_something_wrong'))
            localStorage.removeItem("r_")
            props.loadPlans()
            setTimeout(() => {
                props.setShow(true); 
                props.setRemovePlanModal(false)
            }, 500);
        })
    }

    return (
    <div className='col-sm-12 b-radius overflow-hiden b-shadow bg-white p-0'>
        <Modal.Body>
            <div className="row p-0 m-0 ">
                <div className="col-lg-12 p-4">
                    <div className='pt-3 pb-3 text-center'>
                        <div className='col-sm-12 pb-3'>{t('_remove_plan_desc')}</div>
                        { loading && <FiLoader className="fs-3 icon animated infinite spin slow"/> }

                    </div>
                </div>
            </div>
            <div className='text-center justify-content-center border-top pt-4 pb-3'>
                <button className='net-btn orange-bg orange-shadow pl-4 pr-4 mr-3' onClick={(e) => {props.setShow(true); props.setRemovePlanModal(false)}}>{t('_go_back')}</button>
                <button className='net-btn green-bg green-shadow pl-4 pr-4 mr-3' onClick={(e) => {removePlan(e); }}>{t('_remove_plan')}</button>
            </div>
        </Modal.Body>
    </div>
  )
}

