import React from 'react';
import { Link } from "react-router-dom";
import tv_box from '../../assets/images/svg_tv_box.svg'
import smart_tv from '../../assets/images/svg_smart_tv.svg'
import android_tv from '../../assets/images/svg_android_tv.svg'
import mobile_devices from '../../assets/images/svg_mobile_devices.svg'
import { useTranslation } from 'react-i18next';

export default function Landing_about() {
    const { t } = useTranslation();
  return <>
    <div className="col-sm-12 col-xs-12 p-0 pt-4 pb-5 text text-justify">
        <div className="container pt-2 pb-5">
            <span className="gradient-green-bg gradient-text fw-bolder">ARPINET TV {" "}</span>{t('_headline_')}</div>

        <div className="col-sm-12 col-xs-12 p-0">
        <div className="container">
            <h4 className="text-center">{t('_about_headline_0')}
            <span className="gradient-green-bg gradient-text fw-bolder">{" "}ARPINET TV</span> {t('_about_headline_1')}</h4>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-justify mt-4">
                    <div className="pr-lg-4 pr-sm-0">{t('_about_main_desc_0')}</div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-justify mt-4">
                    <div className="pl-lg-4 pl-sm-0">{t('_about_main_desc_1')}</div>
                </div>
            </div>
            <div className="row pt-5 pb-5 p-0">

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 stretch-card p-0">
                <Link to="#" className="card b-radius b-shadow border-0 cursor-default text-center m-3 p-2">
                    <img alt="Arpinet Mobile TV" className="col-lg-12 col-md-10 col-sm-8 col-xs-6 p-3" src={tv_box}/>
                    <p className="text-center uppercase small mt-4">MAG | ELTEX | TVIP | Dune</p>
                    <p className="text-justify p-3 small text-muted">{t('_about_desc_0')}</p>
                </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 stretch-card p-0">
                <Link to="#" className="card b-radius b-shadow border-0 cursor-default text-center m-3 p-2">
                    <img alt="Arpinet Mobile TV" className="col-lg-12 col-md-10 col-sm-8 col-xs-6 p-3" src={smart_tv}/>
                    <p className="text-center uppercase small mt-4">Samsung Smart TV | LG Smart TV</p>
                    <p className="text-justify p-3 small text-muted">{t('_about_desc_1')}</p>
                </Link>
            </div>

                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 stretch-card p-0">
                    <Link to="#" className="card b-radius b-shadow border-0 cursor-default text-center m-3 p-2">
                        <img alt="Arpinet Mobile TV" className="col-lg-12 col-md-10 col-sm-8 col-xs-6 p-3" src={android_tv}/>
                        <p className="text-center uppercase small mt-4">Android Smart TV | Android TV box</p>
                        <p className="text-justify p-3 small text-muted">{t('_about_desc_2')}</p>
                    </Link>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 stretch-card p-0">
                    <Link to="#" className="card b-radius b-shadow border-0 cursor-default text-center m-3 p-2">
                        <img alt="Arpinet Mobile TV" className="col-lg-12 col-md-10 col-sm-8 col-xs-6 p-3" src={mobile_devices}/>
                        <p className="text-center uppercase small mt-4">Google Play | Apple app store</p>
                        <p className="text-justify p-3 small text-muted">{t('_about_desc_3')}</p>
                    </Link>
                </div>

            </div>
        </div>
        </div>
        </div>
        </>;
}
