import React from 'react'
import { Link } from "react-router-dom";
import { FiAlertCircle } from 'react-icons/fi'
import { useTranslation } from 'react-i18next';

export default function ErrorPage() {
    const { t } = useTranslation();
    return (
        <>
           <div className="col-12 pt-5 pb-5 p-0">
                <div className="row  align-items-center">
                    <div className="col-sm-12 error d-flex justify-content-center">
                        <FiAlertCircle/>
                        <p>{t("_page_unavaible_")}</p>
                        <Link to="/">{t("_page_unavaible_go_back_")}</Link>  
                    </div>
                </div>
            </div> 
        </>
    )
}
