import React from 'react';
import LandingBanner from './Landing_components/LandingBanner';
import LandingAbout from './Landing_components/LandingAbout';
import LandingService from './Landing_components/LandingService';
import LandingAppstores from './Landing_components/LandingAppstores';
import LandingPayments from './Landing_components/LandingPayments';

export default function LandingMain() {
  return <>
            <LandingBanner/>
            <LandingAbout/>
            <LandingService/>
            <LandingAppstores/>
            <LandingPayments/>
        </>;
}
