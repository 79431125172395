import React from 'react'
import {FiWifi} from 'react-icons/fi'
import { useTranslation } from 'react-i18next';

export default function ConnectionStatus() {
  const { t } = useTranslation();
  return (
    <div className='text-center'>
        <FiWifi style={{fontSize:'25vw', color:'#d2d2d2'}}/>
        <div className='mt-4'>
            {t('_hmmm_')}<br/> {t('_disconnected_')}
        </div>
    </div>
  )
}
