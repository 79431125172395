import { useState, useEffect } from 'react'
import axios from 'axios';
import {BASE, REFRESHTOKEN} from '../Requests'

function useRefreshToken( ) {    
    const [ token, setToken ] = useState(false)
    const [ loading, setLoading] = useState(false)
    const [ error, setError ] = useState(null)
      
    useEffect(() => {
        setLoading(true);
        
        var  refresh = JSON.parse(localStorage.getItem("r_"));
        var  key = JSON.parse(localStorage.getItem("key"));
        if(refresh || key){ 

            console.log("test");
            axios({
                method: 'POST',
                url: BASE+REFRESHTOKEN,
                data: {"refresh": key[2] || refresh},
                credentials: "same-origin"
            }).then((res) => {
                localStorage.setItem("e_", res.data.access)
                setToken(true);
                localStorage.removeItem('r_')
            }).catch((err) => {
                setError(err)
                window.location.replace("/login");
                localStorage.removeItem('key')
                localStorage.removeItem('e_')
                localStorage.removeItem('r_')
            }).finally(() => {
                setLoading(false)
            })
        } else {
            window.location.replace("/login");
            localStorage.removeItem('key')
            localStorage.removeItem('e_')
            localStorage.removeItem('r_')
        }
    }, [token])
    return { token, loading, error }
}
export default useRefreshToken;